import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "./cuppon.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";

function Cupon() {
  const [copyState, setcopyState] = useState(false);
  const [alldata, setalldata] = useState([]);
  // const [nochange,setnochange]=useState([]);

  const getAllCoupon = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllCouponCode"
      );
      if (res.status == 200) {
        setalldata(res.data.success);
        // setnochange(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCoupon();
  }, []);
  useEffect(() => {
    getAllCoupon();
  }, []);
  return (
    <>
      <div className="container" style={{ marginTop: "40px" }}>
        <Snackbar
          // invoke snack bar when open is true
          open={copyState}
          // close after three seconds
          autoHideDuration={3000}
          // function called after three seconds
          onClose={() => setcopyState(false)}
          // where the snack bar must be shown
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => setcopyState(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Promo code copy
          </Alert>
        </Snackbar>
        <OwlCarousel
          className="owl-theme "
          loop
          margin={10}
          nav={false}
          items={1}
          autoplay={true}
        >
          {alldata
            ?.filter((ele) => ele?.status !== "Expired")
            ?.map((item) => {
              return (
                <div class="item">
                  {item?.couponImage ? (
                    <div
                      className="jsur"
                      style={{
                        backgroundImage: `url('https://goeasycab.info/Coupon/${item?.couponImage}')`,
                        backgroundSize: "cover",
                        height: "200px",
                      }}
                    >
                      <div className="dis">
                        <div className="dicount">
                          <div className="dis-p">
                            {item?.couponDiscount}%{" "}
                            <span style={{ fontSize: "50px" }}>off</span>
                            <p style={{ color: "brown" }}>{item?.couponType}</p>
                          </div>
                          <div className="fvifd">{item?.title}</div>
                          <div className="gift text-center">
                            <h6>YOUR PROMO CODE</h6>
                            <div className="promo-code mt-3">
                              {item?.couponCode}
                              <CopyToClipboard
                                text={item?.couponCode}
                                onCopy={() => setcopyState(true)}
                              >
                                <div className="copy-area">
                                  <Button variant="contained">
                                    Click to Copy
                                  </Button>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className=""
                      style={{
                        backgroundImage: "url('../yell1.jpg')",
                        backgroundSize: "cover",
                        height: "200px",
                      }}
                    >
                      <div className="dis">
                        <div className="dicount">
                          <div className="dis-p">
                            {item?.couponDiscount}%{" "}
                            <span style={{ fontSize: "50px" }}>off</span>
                            <p style={{ color: "brown" }}>{item?.couponType}</p>
                          </div>
                          <div className="fvifd">{item?.title}</div>
                          <div className="gift text-center">
                            <h6>YOUR PROMO CODE</h6>
                            <div className="promo-code mt-3">
                              {item?.couponCode}
                              <CopyToClipboard
                                text={item?.couponCode}
                                onCopy={() => setcopyState(true)}
                              >
                                <div className="copy-area">
                                  <Button variant="contained">
                                    Click to Copy
                                  </Button>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </>
  );
}

export default Cupon;
