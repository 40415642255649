import React from "react";
import {HiOutlineArrowSmRight} from "react-icons/hi"

function Service() {
  return (
    <>
    <section id="service" className="banner-section">
      <div className="customer " style={{ marginTop: "40px" }}>
        <div className="container">
          <div className="customer-about">
            <div className="row me-0" style={{ alignItems: "center" }}>
              <div className="feature-wrap">
                <div className="section-heading mb-30 wow fade-in-right">
                  <h4 className="white">
                    <span />
                    Why Choose Us!
                  </h4>
                  <h2 className="white">Why Ride with Go Easy Do Cab?</h2>
                  <p className="white">
                    We successfully cope with tasks of varying complexity,
                    provide long-term guarantees and regularly master
                    technologies.
                  </p>
                </div>
                <ul className="ridek-features">
                  <li className="wow fade-in-right">
                    <div className="feature-icon">
                      <i className="las la-gem" />
                    </div>
                    <div className="feature-content">
                      <h3>Safe Guarantee</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                  <li className="wow fade-in-right">
                    <div className="feature-icon">
                      <i className="las la-taxi" />
                    </div>
                    <div className="feature-content">
                      <h3>Fast Pickups</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                  <li className="wow fade-in-right">
                    <div className="feature-icon">
                      <i className="las la-tachometer-alt" />
                    </div>
                    <div className="feature-content">
                      <h3>Quick Ride</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <section className=" cta-section-3 mb-5 mb-3" >
  <div className="container" style={{backgroundColor:"#fbcc30"}}>
    <div className="row align-items-center">
      <div className="col-lg-4">
        <div className="cta-content-0">
          <h4 style={{color:"black"}}>Go Easy Do Cab Services!</h4>
          <h2 style={{fontSize:"30px",color:"white"}}>Feel your journey with Go Easy Do Cab!</h2>
        
        </div>
      </div>
      <div className="col-lg-5">
        <ul className="check-list" style={{listStyle:"none"}}>
          <li style={{color:"white"}}><HiOutlineArrowSmRight className="las la-arrow-right" />Easy payment systems.</li>
          <li style={{color:"white"}}><HiOutlineArrowSmRight className="las la-arrow-right" />Make safety a top priority.</li>
          <li style={{color:"white"}}><HiOutlineArrowSmRight className="las la-arrow-right" />First and quick pickups.</li>
          <li style={{color:"white"}}><HiOutlineArrowSmRight className="las la-arrow-right" />Access Goeasydocab globally.</li>
        </ul>
      </div>
      <div className="col-lg-3">
        <div className="cta-men-7">
      <img src="../Image/driver.avif" alt="" style={{width:"100%"}}/>
      {/* style={{
          backgroundImage:" url('../Image/driver.avif')",
          backgroundRepeat:" no-repeat",
          backgroundPosition:" bottom right",
          position:"absolute"
        </div> */}
        {/* }}/> */}
        </div>
      </div>
    </div>
  </div>
</section>
</>
  );
}

export default Service;
