import React, { useState } from 'react'
import "./Styles/Register1.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import {Link} from 'react-router-dom'
import axios from 'axios';
import {FiPhoneCall} from 'react-icons/fi';
import Buttonn from 'react-bootstrap/Button';
import Modal1 from 'react-bootstrap/Modal';
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from "antd";

function Login() {
    const [passwordVisible, setPasswordVisible] = React.useState(false);



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [phoneNumber,setphoneNumber]=useState("");
    const [password,setPassword]=useState("");
    const [otp,setOtp]=useState("");
    const [loginWit,setLoginwith]=useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [errorData,setErrordata]=useState("")
    const erorShow=(data)=>{
      setErrordata(data)
      setModal2Open(true)
    }
  

    function  isEmpty(str){
        if(typeof (str) == "undefined" || typeof (str) == null) {return false}
        if(typeof (str) == "string" && (str).trim().length == 0) {return false}
        if(typeof (str) == 'number' && (str).toString().trim().length == 0){return false}
        return true
    }

    function  isValidMobile(mobile){
        mobile=mobile?.trim();
        if(mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) ){  //&& ! (mobile.match(/0{5,}/))
            return true;
        }
        return false;
    }

    function isValidEmail(emailid){
        emailid=emailid?.trim();
        if(emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)){
            return true;
        }
        return false;
    }

    const [email,setemail]=useState("")
    const nowlogin=async (e)=>{
      e.preventDefault()
      if(!isEmpty(email)) return erorShow("Please enter email Id!")
      if(!isValidEmail(email)) return erorShow("Invalid email Id!")
      if(!isEmpty(password)) return erorShow("Please enter password!")
      try{
        const config = {
          url: "/loginWithPwd-customer",
          method: "post",
          baseURL: "https://goeasycab.info/api/customer",
          headers: { "conttent-type": "application/json" },
         data:{email:email,Password:password}
        };
    
        let res=await axios(config);
        if(res.status===200){
          alert("Successfully Login")
          sessionStorage.setItem("customer",JSON.stringify(res.data.success));
          sessionStorage.setItem("token",res.data.token)
          window.location.assign("/")
        }
      } catch (error) {
        erorShow(error.response.data.error)
        console.log(error);
      }
    }
  // sendotp
  const sendotp=async (e)=>{
    e.preventDefault()
    if(!isEmpty(phoneNumber)) return erorShow("Please enter mobile number!")
    if(!isValidMobile(phoneNumber)) return erorShow("Invalid mobile number!")
  
    try{
      const config = {
        url: "/login-customer",
        method: "post",
        baseURL: "https://goeasycab.info/api/customer",
        headers: { "conttent-type": "application/json" },
       data:{mobile:phoneNumber}
      };
  
      let res=await axios(config);
      if(res.status===200){
        setShow(true)
      }
    } catch (error) {
      erorShow(error.response.data.error)
      console.log(error);
    }
  }
  const reSendOtp=async ()=>{
  
    if(!isEmpty(phoneNumber)) return erorShow("Please enter mobile number!")
    if(!isValidMobile(phoneNumber)) return erorShow("Invalid mobile number!")
  
    try{
      const config = {
        url: "/login-customer",
        method: "post",
        baseURL: "https://goeasycab.info/api/customer",
        headers: { "conttent-type": "application/json" },
       data:{mobile:phoneNumber}
      };
  
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully resend otp")
      }
    } catch (error) {
      erorShow(error.response.data.error)
      console.log(error);
    }
  }
  const verifyOtp=async ()=>{
  
    if(!isEmpty(otp)) return alert("Please enter your otp!")
    try{
      const config = {
        url: "/verify-otp",
        method: "post",
        baseURL: "https://goeasycab.info/api/customer",
        headers: { "conttent-type": "application/json" },
       data:{mobile:phoneNumber,otp:otp}
      };
  
      let res=await axios(config);
      if(res.status===200){
        alert("Successfully verify otp")
        sessionStorage.setItem("customer",JSON.stringify(res.data.success));
        sessionStorage.setItem("token",res.data.token)
        window.location.assign("/")
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  return (
    <>
    <section className="common-use" style={{
      // backgroundImage:"url('/webImages/bg-login.webp')",
      // height:"auto",
      // backgroundSize:"cover",
      // backgroundPosition:"center",
      // minHeight:"500px"
      margin:"5% 0%"
    }}>
      <div className="user reg-0" >
        <div className="user-re">
          <div class="title-text">
            <h2 style={{fontWeight:"600",color:"#333333"}} >Login</h2>

            <p>Please login below account details</p>
          </div>
          <div className="form-re">
            {loginWit ? (  <form>
              <div className="detail_hero mb-3">
                <label>
                <i class="fa fa-phone" aria-hidden="true"></i>
                </label>

                <input
                  type="number"
                  name="number"
                  placeholder="+91 88990***10"
                  className="regis-pro"
      
                  value={phoneNumber}
                  onChange={(e)=>setphoneNumber(e.target.value)}
                  required
                
                />
              </div>            
              <div class="form-action-button">
                    <button type="submit" onClick={(e)=> sendotp(e)}>Send Otp</button>
                  </div>
                  <br/>
                  <div style={{color:"blue",cursor:"pointer",display:"flex", float:"left"}} onClick={()=>setLoginwith(false)}> Login with password ?</div>
            </form>):(
               <form>
              <div className="detail_hero mb-3">
                <label>
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </label>

                <input
                  type="email"
                  name="email"
                  placeholder="goeasy@gmail.com"
                  className="regis-pro"
      
                  value={email}
                  onChange={(e)=>setemail(e.target.value)}
                  required
                
                />
              </div>
             
              <div className="detail_hero mb-3">
                <label>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </label>
                <Space direction="vertical">
                <Input.Password placeholder="Password"   className="regis-pro" value={password}  onChange={(e)=>setPassword(e.target.value)}/>
              </Space>
              </div>
              
              <div class="form-action-button">
                    <button type="submit" onClick={(e)=>nowlogin(e)}>Login</button>
                  </div>
                  <br/>
                  <div style={{color:"blue",cursor:"pointer",display:"flex", float:"left"}} onClick={()=>setLoginwith(true)}> Login with otp ?</div>
            </form>)}
           
          </div>
          <Modal1 show={show} onHide={handleClose}>
      <Modal1.Header closeButton>
        <Modal1.Title>Otp verification</Modal1.Title>
      </Modal1.Header>
      <Modal1.Body>
        <div className='row'>
          <div className='col-6'>
          <div className="detail_hero mb-6">
                <input
                  type="number"
                  name="number"
                  placeholder="Enter otp"
                  className="regis-pro"
      
                  value={otp}
                  onChange={(e)=>setOtp(e.target.value)}
                  required
                
                />
              </div>
          </div>
          <div className='col-6'>
         
        <Buttonn variant="success" onClick={verifyOtp}>
          Verify
        </Buttonn>
          </div>
        </div>
    </Modal1.Body>
      <Modal1.Footer>
        <Buttonn variant="secondary" onClick={handleClose}>
          Back
        </Buttonn>
        <Buttonn variant="primary" onClick={reSendOtp}>
          Resend
        </Buttonn>
      </Modal1.Footer>
    </Modal1>
        </div>
        <div className="form-re" style={{marginTop:"10%"}}>
              <div className="already">
                  <div className="">Don't have an account?</div>
                  <div class="form-action-button-0">
                  <Link to="/register"><button type="submit" class="ant-btn css-dev-only-do-not-override-1xusghl ant-btn-default hvr-shutter-out-horizontal">Register</button></Link>  
                  </div>
              </div>
          </div>
      </div>
    </section>
    <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
  </>
  )
}

export default Login