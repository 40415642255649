import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Button, Radio } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import useRazorpay from "react-razorpay";
import Autocomplete1 from "react-google-autocomplete";
import Cupon from "./Cupon";
import { FaLocationCrosshairs } from "react-icons/fa6";

function CustomerDetails() {
  const [checkit, setcheckit] = useState(false);
  const [allBanner, setallBanner] = useState(0);
  const [allCoupon, setalldata] = useState([]);
  // const [nochange,setnochange]=useState([]);
  const { state } = useLocation();
  const item = state.item;
  const item1 = state?.item1;
  const state1 = state.state;

  let customer = JSON.parse(sessionStorage.getItem("customer"));
  const admin = sessionStorage.getItem("token");
  const getAllCoupon = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllCouponCode"
      );
      if (res.status == 200) {
        setalldata(res.data.success);
        // setnochange(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [discountShow, setdiscountshow] = useState([]);
  const getAllbaner = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getBanner");
      if (res.status == 200) {
        if (res.data?.success[0]) {
          getAllCoupon()
          setallBanner(res.data?.success[0]?.discount);
          setdiscountshow(res.data?.success[0]?.title);
          settotalDiscont(state?.discount)
          // settotalDiscont(state?.discount+Number(res.data?.success[0]?.discount))
        }
      }
    } catch (error) {
      settotalDiscont(state?.discount)
      console.log(error);
    }
  };
const [totalDiscont,settotalDiscont]=useState(0);
  const [coponCode,setCouponCode]=useState("")
  const [allBooking,setallBooKing]=useState([])
  const [coponData,setCouponData]=useState(false)
const [coponDiscount,setCouponDiscount]=useState("")
let abcdPic= state1?.pickupdate?.split("/")?.reverse()?.join("");

const check=async(data)=>{
  try {
    let stdate=data?.startDate?.split("-")?.join("");
    let endDate=data?.EndDate?.split("-")?.join("");
 
 
      if(data?.couponType=="First Booking"){
       return GetAllBokingData();
      }else if(data?.couponType=="General"){
        alert("Successfully apply")
       return setCouponData(true)
      }else if(parseInt(abcdPic)>=parseInt(stdate)&& parseInt(abcdPic)<=parseInt(endDate)){
        alert("Successfully apply")
      return  setCouponData(true)
      }else{
         alert(`Coupon valid from ${moment(data?.startDate)?.format("DD/MM/YYYY")} to ${moment(data?.EndDate)?.format("DD/MM/YYYY")}`);
       return window.location.reload()
      }
   
  } catch (error) {
    console.log(error);
  }
}
const ApplyCoupon=async()=>{
  try {
    let res=await axios.get("https://goeasycab.info/api/admin/getCouponByCode/"+coponCode);
    if(res.status==200){
      
      check(res.data.success)
      setCouponDiscount(res.data.success?.couponDiscount)
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
const GetAllBokingData=async()=>{
  try {
        if(customer){
     const config = {
      url: "/admin/getOrderByUser/" + customer?._id,
      method: "get",
      baseURL: "https://goeasycab.info/api",
      hearder: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    let res=await axios(config);
    if(res.status==200){
      setallBooKing(res.data.success)
      if(res.data.success?.length==0){
        alert("Successfully apply");
          setCouponData(true)
      }
    }
  }else{
      if(!mobile) return alert("Please fill the form")
     const config = {
      url: "/admin/getBookByMobileNumber/" + mobile,
      method: "get",
      baseURL: "https://goeasycab.info/api",
      hearder: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success)
        setCouponData(true)
    }
  }
  } catch (error) {
    alert(error.response.data.error);
    window.location.reload()
    console.log(error);
  }

}
if(allBooking.length!==0){
  alert("Not your first booking");
  window.location.reload();
}
  const bookda = sessionStorage.getItem("bookid");

  useEffect(() => {
    getAllCoupon()
    getAllbaner();
    if (bookda) {
      sessionStorage.removeItem("bookid");
      window.location.assign("/");
    }
  }, []);
  console.log("check it data", checkit);

const [totalAm,settotalAm]=useState(state.totalAmount>Number(allBanner) ? state.totalAmount-Number(allBanner):state.totalAmount)
  // const admin1 = JSON.parse(sessionStorage.getItem("admin"));

  useEffect(()=>{
    if(coponData==true){
      let ab=(state.totalAmount - (state.totalAmount*(coponDiscount/100)));
      console.log(ab);
      settotalAm(ab)
      settotalDiscont(totalDiscont+(state.totalAmount*(coponDiscount/100)))
  }else if(state.totalAmount>Number(allBanner)){
    settotalAm(state.totalAmount-Number(allBanner))
    settotalDiscont(totalDiscont+Number(allBanner))
  }
  },[coponData,allBanner])



  const [value, setValue] = useState(Math.round(totalAm * (20 / 100)));
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const Razorpay = useRazorpay();

  const d = new Date();
  const [checkradio, setcheckradio] = useState("");

  const [paymentmethod, setpaymentmethod] = useState("");
  const [payid, setpayid] = useState("");
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [orginlat, setorginlat] = useState();
  const [orginlon, setorginlon] = useState();
  const [dropPlace, setDropPlace] = useState("");
  const [distLat, setdistLat] = useState();
  const [distlng, setdistlng] = useState();
  const [city, setcity] = useState("");
  useEffect(() => {
    if (state1.way == "Local") {
      setaddress(state1.from);
      setorginlat(state1.orgLat);
      setorginlon(state1.orgLng);
      setDropPlace(state1?.to);
      setdistLat(state1?.distLat);
      setdistlng(state1?.distLng);
      setcity(state1?.city);
    }
    if (state1.way == "Airport") {
      setaddress(state1.from);
      setorginlat(state1.orgLat);
      setorginlon(state1.orgLng);
      setDropPlace(state1?.to);
      setdistLat(state1?.distLat);
      setdistlng(state1?.distLng);
      setcity(state1?.city);
    }
    if (customer) {
      setname(customer?.name);
      setemail(customer?.email);
      setmobile(customer?.mobile);
    }
  }, []);
  // const [paymethod1,setpaymathode]=useState("Online");
  let PaymetStatus = "Under Process";
  let paymethod1 = "Online";
  const booknow = async () => {
    let waitingTime = "";
    let waitCharge = "";
    if (state1.way == "Local") {
      waitingTime = item?.waitingtime;
      waitCharge = item?.waitingPerMinCharge;
    } else if (state1.way == "oneway" || state1.way == "Round Trip") {
      waitingTime = item1?.waitingTime;
      waitCharge = item1?.waitingCharge;
    } else if (state1.way == "Airport") {
      waitingTime = state?.packageItem?.waittime;
      waitCharge = state.packageItem?.waitCharge;
    }
    try {
      const config = {
        url: "/createOrder",
        method: "post",
        baseURL: "https://goeasycab.info/api/admin",
        headers: { "conttent-type": "application/json" },
        data: {
          vechicleId: item?._id,
          number: mobile,
          email: email,
          picUpPlace: state1.from,
          picUpDate: state1.pickupdate,
          picUpTime: state1?.pickuptime,
          dropPlace: state1?.to,
          userName: name,
          userImg: customer?.profile,
          totalAmount: totalAm,
          tripType: state1?.way,
          gst: state?.gst,
          discount:totalDiscont,
          authId: customer?._id,
          paymentMethod: paymethod1,
          payId: payid,
          payAmount: value,
          totalKm: state1?.km,
          Latitude1: orginlat,
          Longitude1: orginlon,
          Latitude2: distLat,
          Longitude2: distlng,
          returnDate: state1?.retundate,
          returnTime: state1?.returntime,
          picPlace: address,
          DropLocation: dropPlace,
          cancelTimeLimit: item?.cancelTime,
          extraKmPrice: state?.etraFair,
          perKmPrice: state?.perKmPr,
          PaymetStatus: PaymetStatus,
          waitingTime: waitingTime,
          waitingCharge: waitCharge,
          beyondHour: state1?.hr,
          tollCharge:state?.tollCharge,
        parkingCharge:state?.parkingCharge,
          extraHrPrice: item?.extraHrLimetPrice,
          city: city,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        if (payid) {
          sessionStorage.setItem("bookid", res.data.success?.bookid);
          return addAcount(res.data.success?.bookid);
        } else {
          sessionStorage.setItem("bookid", res.data.success?.bookid);
          return window.location.assign("/View-trip");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const posttransaction = async () => {
    try {
      if (!customer) return alert("Please login");
      if (!name) return alert("Please enter full name!");
      if (!email) return alert("Please enter email id!");
      if (!mobile) return alert("Please enter mobile number!");
      if (!address) return alert("Please enter your pick up address");
      if (!value) {
        paymethod1 = "Will be Pay to Driver";
        return booknow();
      }

      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: Math.round(value) * 100,
          currency: "INR",
          name: "Go Easy Cabs",
          description: "Order Amount",
          image: "/Cab-Logo.png",
          customerId: item?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            name: name,
            email: email,
            contact: mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlaceChanged2 = (place) => {
    if (place) {
  
      let am=place?.address_components
      am=am?.filter((ele)=>ele?.long_name==state1?.from);
      // console.log("am",am);
      if(am?.length==0) return alert(`Please enter pick up location in side ${state1?.from}`)
      setorginlat(place.geometry.location.lat());
      setorginlon(place.geometry.location.lng());
      setaddress(place.name);
    }
  };
  const handlePlaceChanged42 = (place) => {
    if (place) {
      // console.log("check it", place);
      let am=place?.address_components
      am=am?.filter((ele)=>ele?.long_name==state1?.to);
      if(am?.length==0) return alert(`Please enter drop location in side ${state1?.to}`)

      setdistLat(place.geometry.location.lat());
      setdistlng(place.geometry.location.lng());
      setDropPlace(place.name);
    }
  };

  const addAcount = async (bookid) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://goeasycab.info/api",
        headers: { "content-type": "application/json" },
        headers: { Authorization: `Bearer ${admin}` },
        data: {
          authId: customer?._id,
          customeId: customer?._id,
          bookingId: bookid,
          paymentId: payid,
          status: "Cr",
          paymentStatus: "Success",
          amount: totalAm,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        return window.location.assign("/View-trip");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (payid) {
      if (totalAm == value) {
        PaymetStatus = "Full Payment";
      }
      booknow();
    }
  }, [payid]);

  const [area, setArea] = useState(null);

  function CurrentLocation(){

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
     
 
      setorginlat(latitude);
      setorginlon(longitude);
     
     
        // Use a reverse geocoding service to get area information
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          if(response.data?.address?.city!==state1?.from) return alert(`Your Current  location is not  in side ${state1?.from}`)
          setArea(response.data.display_name);
       
          setaddress(response.data.display_name);
   
        } catch (error) {
        return alert('Error fetching area information:');
        }
      });
    } else {
     return alert('Geolocation is not supported by your browser');
    }
  }
  return (
    <>
      <section className="banner-section">
        <div className="vehicle">
          <Container>
            <div className="custoumer">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="customer-re">
                    <div className="customer-review">
                      <h5>Review your booking details</h5>
                      {/* 
                      <p className="pt-2 mb-2">
                        Bangalore &#10094; Mysore{" "}
                        <span>&#10088;Oneway&#10089;</span>{" "}
                      </p> */}
                      {state1?.way == "Local" ? (
                        <p className="pt-2 mb-2">
                          {state1?.from} ({state1?.to}){" "}
                        </p>
                      ) : (
                        <p className="pt-2 mb-2">
                          {state1?.from} {">"}
                          {state1?.to}
                          {state1?.way == "Round Trip" ? (
                            <>
                              {">"}
                              {state1?.from}
                            </>
                          ) : (
                            ""
                          )}{" "}
                          <span>&#10088;{state1?.way}&#10089;</span>{" "}
                        </p>
                      )}
                      <div className="vechile-0 mf pt-2 mb-2">
                        <div className="vechile-date">
                          <div>Pickup:</div>
                          <div>{state1?.pickupdate}</div>
                        </div>
                        <div className="vechile-time">
                          <div>Time:</div>
                          <div>
                            {" "}
                            {moment(state1?.pickuptime, ["h:mm a"]).format(
                              "hh:mm a"
                            )}
                          </div>
                        </div>
                      </div>
                      {state1?.retundate ? (
                        <div className="vechile-0 mf pt-2 mb-2">
                          <div className="vechile-date">
                            <div>Return date:</div>
                            <div>{state1?.retundate}</div>
                          </div>
                          <div className="vechile-time">
                            <div> Return time:</div>
                            <div>
                              {moment(state1?.returntime, ["h:mm a"]).format(
                                "hh:mm a"
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="cav-details pt-2 mb-2">
                        <div className="cav-deatils-0">
                          {item?.vehicleImage ? (
                            <a
                              href={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                              target="_blank"
                            >
                              <img
                                src={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                                alt="not-found"
                                className="image-thub"
                              />
                            </a>
                          ) : (
                            <img
                              src="../Image/car-1.png"
                              alt="not-found"
                              className="image-thub"
                            />
                          )}
                        </div>
                        <div className="cab-full-details">
                          <h5>{item?.category}</h5>
                          <ul className="space-df mb-4">
                            <li>{item.vehModel}</li>
                            <li>|</li>
                            <li>{item?.isAc}</li>
                            <li>|</li>
                            <li>{item?.seatAv} Seats</li>
                          </ul>
                          {allBanner ? <h5>{discountShow}</h5> : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="user-details">
                    <div className="user_hero">
                      <h5>Traveller Details</h5>
                      <div className="form-groups mb-2">
                        <input
                          name="custname"
                          className="user_name"
                          type="text"
                          id="custname"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="form-groups mb-2">
                        <input
                          name="custname"
                          className="user_name"
                          type="text"
                          id="custname"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Enter Email Id"
                        />
                      </div>
                      <div className="form-groups mb-2">
                        <input
                          name="custname"
                          className="user_name"
                          type="text"
                          id="custname"
                          value={mobile}
                          onChange={(e) => setmobile(e.target.value)}
                          placeholder="Enter Mobile No"
                        />
                      </div>
                      {state1.way == "Local" || state1.way == "Airport" ? (
                        <></>
                      ) : (
                        <><div    className="user_name"style={{display:"flex",justifyContent:"center",alignItems:"baseline",gap:"5px"}}>
                             <p  onClick={CurrentLocation}><FaLocationCrosshairs style={{color:"green"}}/></p>
                           <div className="form-groups mb-2">
                            <Autocomplete1
                            style={{
                              width:"560px"
                            }}
                              apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                              placeholder="Enter your pick up place"
                              className="user_name"
                              defaultValue={area}
                              onPlaceSelected={(place) => {
                                handlePlaceChanged2(place);
                              }}
                              options={{
                                // types: ["(resoins)"],
                                componentRestrictions: { country: "in" },
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "icon",
                                  "name",
                                ],
                                types: ["establishment"],
                              }}
                            />
                          </div>
                        </div>
                         
                          <div className="form-groups mb-2">
                            <Autocomplete1
                              apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                              placeholder="Enter your drop place"
                              className="user_name"
                              onPlaceSelected={(place) => {
                                handlePlaceChanged42(place);
                              }}
                              options={{
                                // types: ["(resoins)"],
                                componentRestrictions: { country: "in" },
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "icon",
                                  "name",
                                ],
                                types: ["establishment"],
                              }}
                            />
                          </div>
                      
                        </>
                      )}
                      {allCoupon?.filter((ele)=>ele?.status!=="Expired")?.length!=0 ? (  <div className="form-groups mb-2" style={{display:"flex",justifyContent:"space-between"}}>
                            <div>
                              <input
                                placeholder="Enter your promo code"
                                className="user_name"
                                onChange={(e)=>setCouponCode(e.target.value)}
                              />
                            </div>
                            {coponCode ? (  <Button style={{backgroundColor:" #fbcc30",}} onClick={ApplyCoupon}>Apply</Button>):(<></>)}
                          
                          </div>):(<></>)}
                        {totalDiscont? (   <p style={{color:"green",fontWeight:"bold",fontSize:"20px"}}>You  saved ₹ {totalDiscont?.toFixed(2)}</p>):(<></>)}
                       
                        
                    </div>
                  </div>
                </div>
              </div>
              <Cupon />
              <div className="inclusions ">
                <div className="inclusions_hero">
                  <div
                    className="customer_extra"
                    style={{
                      backgroundColor: "#ffc604",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Inclusions (Included in the Price)
                  </div>
                  {state1.way == "Local" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                       <li className="mt-3 mb-2">Driver Allowance</li>
                      <li className="mt-3 mb-2">
                      {state1?.hr} hours (booked package)   
                      </li>
                      <li className="mt-3 mb-2">{state1?.km} kms  (booked package)  </li>
                     
                    
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "Airport" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Night charges ({item?.nightTime})
                      </li>
                      <li className="mt-3 mb-2">Toll Charges</li>
                      <li className="mt-3 mb-2">State Tax</li>
                      <li className="mt-3 mb-2">Airport entry charges</li>
                      <li className="mt-3 mb-2">{state1?.km} Kms</li>
                      <li className="mt-3 mb-2">Driver Allowance</li>
                      <li className="mt-3 mb-2">
                        Only One Pickup and Drop ( Only one included )
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "oneway" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Night charges ( {item?.nightTime} )
                      </li>
                      <li className="mt-3 mb-2">Parking Charges </li>
                      <li className="mt-3 mb-2">Toll Charges</li>
                      <li className="mt-3 mb-2">State Tax</li>
                      <li className="mt-3 mb-2">{state1?.km} Kms</li>
                      <li className="mt-3 mb-2">Driver Allowance</li>
                      <li className="mt-3 mb-2">
                        Only One Pickup and Drop ( Only one included )
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "Round Trip" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">{state1?.km} Kms</li>
                      <li className="mt-3 mb-2">Driver Allowance</li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="inclusions_hero-09"
                  style={{ border: "1px solid #0000002e" }}
                ></div>
                <div className="inclusions_hero">
                  <div
                    className="customer_extra kk"
                    style={{ backgroundColor: "black", fontSize: "20px" }}
                  >
                    Exclusions (Extra Charges)
                  </div>
                  {state1.way == "Local" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Toll Charges
                        <span className="extra-price">As applicable</span>
                      </li>
                      <li className="mt-3 mb-2">
                        State Tax{" "}
                        <span className="extra-price">As applicable</span>
                      </li>
                      <li className="mt-3 mb-2">
                        Waiting Charges{" "}
                        <span className="extra-price">
                          After {item?.waitingtime} , ₹{" "}
                          {item?.waitingPerMinCharge}
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Fare beyond {state1?.km} Kms
                        <span className="extra-price">
                          ₹ {state?.etraFair}/Km
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Fare beyond {state1?.hr} Hrs
                        <span className="extra-price">
                          ₹ {item?.extraHrLimetPrice}/
                          Minutes
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "oneway" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Waiting Charges{" "}
                        <span className="extra-price">
                          After {item1?.waitingTime} ₹ {item1?.waitingCharge}
                          
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Fare beyond {state1?.km} Kms
                        <span className="extra-price">
                          ₹ {state?.etraFair}/Km
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "Round Trip" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Night charges({item?.nightTime})
                        <span className="extra-price">
                          ₹ {item?.nightCharge}
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Toll Charges
                        <span className="extra-price">As applicable</span>
                      </li>
                      <li className="mt-3 mb-2">
                      Parking Charges
                        <span className="extra-price">As applicable</span>
                      </li>
                      <li className="mt-3 mb-2">
                        State Tax{" "}
                        <span className="extra-price">As applicable</span>
                      </li>
                      <li className="mt-3 mb-2">
                        Waiting Charges{" "}
                        <span className="extra-price">
                          After {item1?.waitingTime} , ₹ {item1?.waitingCharge}
                         
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Fare beyond {state1?.km} Kms
                        <span className="extra-price">
                          ₹ {state?.etraFair}/Km
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {state1.way == "Airport" ? (
                    <ul className="mt-3 mb-2 py-1 pe-2">
                      <li className="mt-3 mb-2">
                        Waiting Charges{" "}
                        <span className="extra-price">
                          After {state?.packageItem?.waittime}, ₹{" "}
                          {state.packageItem?.waitCharge}
                        </span>
                      </li>
                      <li className="mt-3 mb-2">
                        Fare beyond {state1?.km} Kms
                        <span className="extra-price">
                          ₹ {state?.etraFair}/Km
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {/* BookingPayment */}
              <div className="booking-0">
                <div className="pay_0">
                  <div className="pt-3 pb-3">
                    <input
                      type="checkbox"
                      onChange={(e) => setcheckit(!checkit)}
                    />
                    <label className="ps-3">
                      By proceeding to book, I Agree to Go Easy Do Cab's{" "}
                      <span
                        style={{
                          color: "#0041ff",
                          borderBottom: "1px solid #0041ff",
                        }}
                      >
                          <a href="/privacypolicy">
                        Privacy Policy</a>
                      </span>
                      ,
                      <span
                        style={{
                          color: "#0041ff",
                          borderBottom: "1px solid #0041ff",
                        }}
                      >
                        {" "}
                        <a href="/user-agreement">
                        User Agreement</a>
                      </span>{" "}
                      and{" "}
                      <span
                        style={{
                          color: "#0041ff",
                          borderBottom: "1px solid #0041ff",
                        }}
                      
                      >
                        <a href="/terms-condition">
                        Terms of Service</a>
                      </span>
                      .
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="">
                        <h5>Total Amount: ₹{totalAm}</h5>
                      </div>
                      <small>Inclusive of GST</small>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="remain_distance">
                        <div className="remain_distance-0">
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={Math.round(totalAm * (20 / 100))}>
                              Pay ₹{Math.round(totalAm * (20 / 100))} Now{" "}
                              <br className="" />
                              <small>Remaining to driver </small>
                            </Radio>
                            <Radio value={totalAm}>
                              Pay ₹{totalAm} Now <br />
                              <small>Full payment </small>
                            </Radio>
                            <Radio value={0}>
                              Will be Pay to Driver
                              <br />
                              <small>Full payment </small>
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 ">
                      {checkit ? (
                        <div className=" text-center mb-3">
                          <Button
                            className="hvr-shutter-out-horizontal"
                            onClick={posttransaction}
                          >
                            Pay Now
                          </Button>
                        </div>
                      ) : (
                        <div className=" text-center mb-3">
                          <Button
                            className="hvr-shutter-out-horizontal"
                            style={{
                              backgroundColor: "#f3dc8f",
                              border: "1px solid #f3eedc",
                            }}
                            onClick={() =>
                              alert("Please check Terms And Condition!")
                            }
                          >
                            Pay Now
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default CustomerDetails;
