import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "../Admin/Admin.css";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import moment from "moment";

function Admindashboard() {
  const admin = sessionStorage.getItem("token");
  const [dataSource, setdataSource] = useState([]);

  const AllUsers = async () => {
    try {
      const config = {
        url: "/customer/getAllCustomer",
        method: "get",
        baseURL: "https://goeasycab.info/api",
        headers: { Authorization: `Bearer ${admin}` },
      };
      let res = await axios(config);
      console.log("check===>", res);
      if (res.status === 200) {
        console.log("check===>", res);
        setdataSource(res.data.success);
      }
    } catch (error) {
      console.log("errror==>", error);
    }
  };
  useEffect(() => {
    AllUsers();
  }, []);
  const [data1, setdata1] = useState([]);
  

  const Alldrivers = async () => {
    try {
        const config={
            url:"/driver/getAllDriverAndVehicle",
            method:"get",
            baseURL:"https://goeasycab.info/api",
            headers: {"Authorization" : `Bearer ${admin}`},
         
        }
        let res = await axios(config);
        console.log("check===>",res)
        if (res.status === 200) {
            console.log("check===>",res)
          setdata1(res.data.success);
        }
    } catch (error) {
        console.log("errror==>",error);
    }
   
  };
  // const admin = sessionStorage.getItem("token");
  const admin1 = JSON.parse(sessionStorage.getItem("admin"));
const [data,setdata]=useState([]);
  const [nochangedata,setnochangedata]=useState([])
  const getBooking = async () => {
    const config = {
      url: "/admin/getallOrder/" + admin1?._id,
      method: "get",
      baseURL: "https://goeasycab.info/api",
      hearder: { "content-type": "application/json" },
      headers: { Authorization: `Bearer ${admin}` },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
   
        setdata(result.data.success);
        setnochangedata(result.data.success);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Alldrivers();
    getBooking();
  }, []);
  let today=moment( ).format("DD/MM/YYYY")
  let tommarow= moment( new Date().setDate(new Date().getDate() +1)).format("DD/MM/YYYY");
  let Yesterday=moment( new Date().setDate(new Date().getDate() -1)).format("DD/MM/YYYY");
  let lastweek=moment( new Date().setDate(new Date().getDate() -7)).format("YYYY-MM-DD")
  let lw=parseInt(lastweek?.split("-")?.join(""))
  let lastMonth=moment( new Date().setDate(new Date().getDate() -30)).format("YYYY-MM-DD");
  let lm=parseInt(lastMonth?.split("-")?.join(""))

  const filterData=(abc)=>{
    try {
      let am=nochangedata.filter((ele)=>{
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        if(abc=="Today"){
          return  ele?.picUpDate==today
        }else if(abc=="Tomorrow"){
          return  ele?.picUpDate==tommarow
        }else if(ele?.picUpDate=="Yesterday"){
          return abd==Yesterday
        } else if(abc=="Last week"){
          return amd>=lw
        }else{
          return amd>=lm
        }
      })
     return setdata(am)
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{ paddingLeft: "30px" }}>
     <div className=""style={{display:"flex",justifyContent:"space-between"}}>
     <Button
            style={{
              color: "white",
              backgroundColor: "#fdd12d",
              border: "#fdd12d",
            }}
          >
            {" "}
            Dashboard
          </Button>
   
      <Form.Select aria-label="Default select example" style={{width:"auto"}} onChange={(e)=>filterData(e.target.value)}>
      <option>Open this select menu</option>
      <option value="Today">Today</option>
      <option value="Tomorrow">Tomorrow</option>
      <option value="Yesterday">Yesterday</option>
      <option value="Last week">Last week</option>
      <option value="Last Month">Last Month</option>
      </Form.Select>
     </div>
  
      <br></br>
      <div className="row" style={{paddingTop:"130px"}}>
        <div className="col-3 mb-3">
       
            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>{dataSource.length}</Card.Title>
                <Card.Text className="countt">No. of Users</Card.Text>
              </Card.Body>
            </Card>
    
        </div>
        <div className="col-3 mb-3">
        
            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                  {data1.length}
                </Card.Title>
                <Card.Text className="countt">No. of Drivers</Card.Text>
              </Card.Body>
            </Card>
      
        </div>
        <div className="col-3 mb-3">

<Card
  style={{
    width: "200px",
    height: "118px",
    boxShadow: "0px 0px 8px #fdd12d",
    backgroundColor: "#fdd12d",
  }}
>
  <Card.Body
    style={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Card.Title style={{ color: "white" }}>
      {nochangedata.filter((ele) => moment(ele?.createdAt).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY")).length}
    </Card.Title>
    <Card.Text className="countt">Today Booking</Card.Text>
  </Card.Body>
</Card>

</div>
        <div className="col-3 mb-3">

            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                  {data.filter((ele) => ele?.isUpcome == true).length}
                </Card.Title>
                <Card.Text className="countt">Upcoming Bookings </Card.Text>
              </Card.Body>
            </Card>
       
        </div>
        <div className="col-3 mb-3">

            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                  {data.length}
                </Card.Title>
                <Card.Text className="countt">Total Booking</Card.Text>
              </Card.Body>
            </Card>
      
        </div>
        <div className="col-3 mb-3">

            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                  {data?.filter((ele) => ele?.status == "Complete").length}
                </Card.Title>
                <Card.Text className="countt">Completed Booking</Card.Text>
              </Card.Body>
            </Card>
        
        </div>
        <div className="col-3 mb-3">

            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                  {data?.filter((ele) => ele?.status == "Cancel").length}
                </Card.Title>
                <Card.Text className="countt">Cancelled Booking</Card.Text>
              </Card.Body>
            </Card>
      
        </div>
     
        <div className="col-3 mb-3">

            <Card
              style={{
                width: "200px",
                height: "118px",
                boxShadow: "0px 0px 8px #fdd12d",
                backgroundColor: "#fdd12d",
              }}
            >
              <Card.Body
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card.Title style={{ color: "white" }}>
                {"₹ "}  {data?.reduce((a,ele)=>a=ele.totalAmount,0)}
                </Card.Title>
                <Card.Text className="countt">Booking Value</Card.Text>
              </Card.Body>
            </Card>
    
        </div>
      </div>
    </div>
  );
}

export default Admindashboard;
