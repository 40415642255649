import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Button, Modal } from "antd";
import { RxCrossCircled } from "react-icons/rx";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-multi-date-picker";
import { Radio } from "antd";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import { DistanceMatrixService } from "@react-google-maps/api";
import Autocomplete1 from "react-google-autocomplete";
import Autocomplete2 from "react-google-autocomplete";
import Cupon from "./Cupon";
function SearchCav() {
  const [modal2Open1, setModal2Open1] = useState(false);
 const [value, setValue] = useState(2);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [errorData, setErrordata] = useState("");
  const erorShow = (data) => {
    setErrordata(data);
    setModal2Open1(true);
  };
  const { state } = useLocation();
  const [outStation, setoutStation] = useState("");
  const [airport, setairport] = useState("");
  const [local, setlocal] = useState("");
  const [airportSt, setairportSt] = useState("");
  const history = useNavigate();
  const [distance, setdistance] = useState(0);

  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [pickupdate, setpickupdate] = useState(moment().format("DD/MM/YYYY"));
  const [retundate, setretundate] = useState(moment().format("DD/MM/YYYY"));
  const [pickuptime, setpickuptime] = useState("");
  const [returntime, setreturntime] = useState("");
  const [localAd, setLocalAd] = useState("");
  const [city, setcity] = useState("");
  const [isSold, setSold] = useState(false);
  const [isSold2, setSold2] = useState();
  const [rountTrip, setroundTrip1] = useState("oneway");
  const [allAirpots, setAllAirports] = useState([]);
  var [nochanorlat, setnochanorlat] = useState();
  const [localPack, setLocalPack] = useState({});
  var [nochangeOrlng, setnochangeOrlng] = useState();
  const [airportName, setairportName] = useState();
  const [parseData, setparseData] = useState({});
  const getAirportDetails = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllAirportsDetails"
      );
      if (res.status == 200) {
        setAllAirports(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allCity, setAllcity] = useState([]);
  const getAllCity = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllAirportCity"
      );
      if (res.status === 200) {
        setAllcity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [loclSold, setLocalSold] = useState(false);
  const [oneWay, setoneWay] = useState(false);
  const [roundTrip, setroundTrip] = useState(true);
  useEffect(() => {
    getAllCity();
    getAirportDetails();
    setfrom(state.from);
    setto(state.to);
    if (state.way == "Airport") {
      setairport("true");
      setlocal("");
      setoutStation("");
      let picdate = state?.pickupdate?.split("/").reverse();
      let pick2 = parseInt(picdate?.join(""));
      // console.log(" state?.parseData", state?.parseData);

      if (
        state?.parseData?.airportType == "Both" &&
        parseInt(state?.parseData?.to?.split("-")?.join("")) >= pick2 &&
        parseInt(state?.parseData?.from?.split("-")?.join("")) <= pick2
      ) {
        setSold(true);
        setSold2(true);
      } else if (
        state?.airport == "true" &&
        state?.parseData?.airportType == "Airport Pickup" &&
        parseInt(state?.parseData?.to?.split("-")?.join("")) >= pick2 &&
        parseInt(state?.parseData?.from?.split("-")?.join("")) <= pick2
      ) {
        setSold(true);
        setSold2(true);
      } else if (
        state?.airport == "false" &&
        state?.parseData?.airportType == "Airport Drop" &&
        parseInt(state?.parseData?.to?.split("-")?.join("")) >= pick2 &&
        parseInt(state?.parseData?.from?.split("-")?.join("")) <= pick2
      ) {
        setSold(true);
        setSold2(true);
      } else {
        setSold(false);
        setSold2(false);
      }

      setpickupdate(state?.pickupdate);
    } else if (state?.way == "Local") {
      setlocal("true");
      setairport("");
      setoutStation("");
      setLocalPack(state?.pack);
      setkm(state.km);
      sethrs(state.hr);
      setnolat(state?.nolat);
      // setdistance(state?.km);
      setnolng(state.nolng);
      setdroplat(state?.distLat);
      setdroplong(state?.distLng);
      setpickupdate(state?.pickupdate);
      setpickuptime(state?.pickuptime);
    } else if (state.way != "Airport" && state.way != "Local") {
      setoutStation("true");
      setlocal("");
      setairport("");
      let picdate = state?.pickupdate?.split("/").reverse();
      let pick2 = parseInt(picdate?.join(""));
      if (state?.am?.length == 1) {
        if (
          state?.am[0]?.isSold == true &&
          parseInt(state?.am[0]?.soldEnd?.split("-")?.join("")) >= pick2 &&
          parseInt(state?.am[0]?.soldStart?.split("-")?.join("")) <= pick2
        ) {
          setSold(true);
        }
      } else {
        setSold(false);
      }
      if (state.way == "oneway") {
        setoneWay(true);
        setroundTrip(false);
        setValue(1)
        setdistance(state?.km);
      } else if (state.way == "Round Trip") {
        setroundTrip(true);
        setoneWay(false);
        setValue(2)
        setpickupdate(state?.pickupdate);
        setpickuptime(state?.pickuptime);
        setretundate(state.retundate);
        setreturntime(state.returntime);
        setfrom(state.from);
        // setdistance(state?.km);
        setto(state.to);
        setroundTrip1("Round Trip");
      }
    }
  }, []);
  //check it

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

 

  const [allroutesdata, setAllroutes] = useState([]);
  const getAllroutes = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getRoute");
      if (res.status === 200) {
        setAllroutes(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allvehicle, setvehicle] = useState([]);
  const getAllVehicle = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllVehicle"
      );
      if (res.status === 200) {
        setvehicle(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [gst, setgst] = useState({});
  const getgst = async () => {
    try {
      let res = await axios.get("https://goeasycab.info/api/admin/getgst");
      if (res.status == 200) {
        setgst(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [modal2Open, setModal2Open] = useState(false);

  const [allpackage, setPackage] = useState([]);
  const getAllPackage = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getAllPackage"
      );
      if (res.status == 200) {
        setPackage(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllroutes();
    getAllVehicle();
    getAllPackage();
    getgst();
  }, []);
  const top100Films = [];
  const top100Films2 = [];
  if (allroutesdata.length != 0) {
    for (let i = 0; i < allroutesdata.length; i++) {
      if (top100Films[i - 1]?.label !== allroutesdata[i]?.from) {
        top100Films.push({
          label: allroutesdata[i]?.from,
          // year: allroutesdata[i]?.totalKm,
        });
      }
      if (top100Films2[i - 1]?.label !== allroutesdata[i]?.to) {
        top100Films2.push({
          label: allroutesdata[i]?.to,
          // year: allroutesdata[i]?.totalKm,
        });
      }
    }
  }



  const outstationpack = state?.packagedata;
  const [address, setaddress] = useState("");
  const [orginlat, setorginlat] = useState();
  const [orginlon, setorginlon] = useState();
  const [distlat, setdistlat] = useState();
  const [distlon, setdistlon] = useState();
  const [nolat, setnolat] = useState();
  const [nolng, setnolng] = useState();
  const [km, setkm] = useState(0);
  const [hrs, sethrs] = useState(0);
  const [packageD, setPdc] = useState({});
  const [ab, setab] = useState("");

  const [mat, setMat] = useState(false);
  const handlePlaceChangedBcd2 = (place) => {
    if (place) {
      // alert("fjkhjkf")
      console.log("check it", place.name);
      setdistlat(place.geometry.location.lat());
      setnolat(place.geometry.location.lat());
      setdistlon(place.geometry.location.lng());
      setnolng(place.geometry.location.lng());
      setaddress(place.name);
      setfrom(place.name);
      setLocalAd(place.name);
      setMat(true);
      setab(place.geometry.location.lng());
    }
  };
  const handlePlaceChangedL = (place) => {
    if (place) {
      console.log("check it", place.name);
      setdistlat(place.geometry.location.lat());
      setnolat(place.geometry.location.lat());
      setdistlon(place.geometry.location.lng());
      setnolng(place.geometry.location.lng());
      // setaddress(place.name);
      setfrom(place.name);
      setLocalAd(place.name);
      // setMat(true);
    }
  };
  const [airportStatus, setairportSatus] = useState("");
  const HandeleAirport = (ab) => {
    try {
      if (ab) {
        let data = JSON.parse(ab);
        setparseData(data);
        setnochanorlat(data?.lat);
        setorginlat(data?.lat);
        setnochangeOrlng(data?.lng);
        setorginlon(data?.lng);
        setSold2(data?.isSould);
        setairportName(data?.airportName);
        setairportSatus(data?.lng);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (airport == "true") {
      if (airportSt == "true") {
        if (address) {
          setorginlat(nochanorlat);
          setorginlon(nochangeOrlng);
          setdistlat(nolat);
          setdistlon(nolng);
        }
        setfrom(airportName);
        setto(address);
      } else if (airportSt == "false") {
        if (address) {
          setdistlat(nochanorlat);
          setdistlon(nochangeOrlng);
          setorginlat(nolat);
          setorginlon(nolng);
        }
        setfrom(address);
        setto(airportName);
      }
    }
  }, [address, airportSt,airportStatus]);

  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
  ];
  const [am1, setam1] = useState([]);
  useEffect(() => {
    let today = new Date();
    if (moment(today).format("DD/MM/YYYY") == pickupdate) {
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint = 0;
      if (later.getMinutes() < 10) {
        mint = `0${later.getMinutes()}`;
      } else {
        mint = later.getMinutes();
      }
      let abc = `${later.getHours()}${mint}`;
      setam1(alldate.filter((ele) => ele.id >= parseInt(abc)));
      setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time);
      setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time);
    } else {
      setam1(alldate);
      setpickuptime("");
      setreturntime("");
    }
  }, [pickupdate]);

  useEffect(() => {
    let today = new Date();
    if (moment(today).format("DD/MM/YYYY") == retundate) {
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint = 0;
      if (later.getMinutes() < 10) {
        mint = `0${later.getMinutes()}`;
      } else {
        mint = later.getMinutes();
      }
      let abc = `${later.getHours()}${mint}`;
      setam1(alldate.filter((ele) => ele.id >= parseInt(abc)));
      // setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time)
      setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time);
    } else {
      setam1(alldate);
      // setpickuptime("")
      setreturntime("");
    }
  }, [retundate]);

  useEffect(() => {
    let today = new Date();
    if (moment(today).format("DD/MM/YYYY") == pickupdate) {
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint = 0;
      if (later.getMinutes() < 10) {
        mint = `0${later.getMinutes()}`;
      } else {
        mint = later.getMinutes();
      }
      let abc = `${later.getHours()}${mint}`;
      setam1(alldate.filter((ele) => ele.id >= parseInt(abc)));
      setpickuptime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time);
      setreturntime(alldate.filter((ele) => ele.id > parseInt(abc))[0].time);
    } else {
      setam1(alldate);
      setpickuptime("");
      setreturntime("");
    }
  }, []);

  var date1 = pickupdate?.split("/");
  var date2 = retundate?.split("/");
  //
  var da1 = new Date(date1[2], date1[1], date1[0]);
  var da2 = new Date(date2[2], date2[1], date2[0]);
  var md1 = moment(da1);
  var md2 = moment(da2);
  var days = md2.diff(md1, "days", false);
  const day=days+1;
  
  console.log("days",day, days, state);

  const [ActiveRoute, setActiveRouts] = useState([]);

  const getActiveRoute = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getActiveRoutes"
      );
      if (res.status == 200) {
        setActiveRouts(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveRoute();
  }, []);
  const handlePlaceChanged1 = (place) => {
    if (place) {
      setcity(place.name);
    }
  };
  const [droplat, setdroplat] = useState();
  const [droplong, setdroplong] = useState();
  const handlePlaceToDrop = (place) => {
    if (place) {
      setdroplat(place.geometry.location.lat());
      setdroplong(place.geometry.location.lng());
      setto(place.name);
    }
  };
  const checkdata = () => {
    try {
      if (outStation) {
        // erorShow("A");
        setab("");
        let am = allroutesdata?.filter(
          (ele) =>
            ele?.from?.toLowerCase() === from?.toLowerCase() &&
            ele?.to?.toLowerCase() === to?.toLowerCase() &&
            ele.roundTrip == "oneway" &&
            ele.isActive == true
        );

        if (oneWay) {
          // erorShow("B");
          if (am.length == 0) {
            return setModal2Open(true);
          }
          if (am[0].isActive == false) {
            return setModal2Open(true);
          }

          handleCancel();
          history("/search", {
            state: {
              from: from,
              to: to,
              pickupdate: pickupdate,
              pickuptime: pickuptime,
              way: "oneway",
              km: am[0]?.totalKm,
              packagedata: am[0],
              am: am,
            },
          });
          return window.location.reload();
        } else {
          // erorShow("C");
          am = allroutesdata?.filter(
            (ele) =>
              ele?.from?.toLowerCase() === from?.toLowerCase() &&
              ele?.to?.toLowerCase() === to?.toLowerCase() &&
              ele.roundTrip == "Round Trip" &&
              ele.isActive == true
          );
          if (am.length == 0) {
            return setModal2Open(true);
          }
          if (!retundate) return erorShow("Please select return date!");
          if (!returntime) return erorShow("Please select time");
          if (am[0].isActive == false) {
            return setModal2Open(true);
          }
          let km=am[0]?.totalKm * 2
          let km2=250
          
            if(days>0){
              km2=250*(days+1)
              if(km>km2){
                km=km
              }else if(km2>km){
                km=km2
              }
              days= days+1
            }else{
            
              if(km<250){
                km=250
              }
              days= days+1
            }
          // let km = am[0]?.totalKm * 2;
          // let km2 = am[0]?.totalKm;
          // if(km<250){
          //   km=250
          // }
          // if(km2<=250 && days>0){
          //   km=250*(days+1)
          //   days= days+2
          // }else if(km2>250 && days>0){
          //   km=km2*(days+1)
          //   days= days+2
          // }else{
          //   days= days+1
          // }
          // if(days>3&&km2>250){
          //   km=250*(days-1)
          // }
          // erorShow("a",days)
          handleCancel();
          history("/search", {
            state: {
              from: from,
              to: to,
              pickupdate: pickupdate,
              pickuptime: pickuptime,
              way: "Round Trip",
              retundate: retundate,
              returntime: returntime,
              km: km,
              packagedata: am[0],
              days: days + 1,
              am: am,
            },
          });
          return window.location.reload();
        }
      }
      if (airport) {
        if (!from) return erorShow("Please fill the blank");
        if (!to) return erorShow("Please fill the blank");
        let pickdate = pickupdate?.split("/").reverse();
        let ab = parseInt(pickdate?.join(""));

        var pickT = pickuptime?.split(":");
        let piTime = parseInt(pickT?.join(""));

        let am = activePlan
          ?.filter((item) => item?.city == city)
          ?.filter((ele) => {
            let startd = ele?.startDate?.split("/")?.reverse();
            let date1 = ele?.ToDate?.split("/").reverse();

            let start = parseInt(startd?.join(""));
            let end = parseInt(date1?.join(""));
            return end >= ab && start <= ab;
          });

        if (am?.length != 0) {
          var timest = am[0]?.startTime?.split(":");
          let sTtime = parseInt(timest?.join(""));

          var timeEnd = am[0]?.ToTime?.split(":");
          let endend = parseInt(timeEnd?.join(""));
          // console.log("timefilter", sTtime, endend);
          if (piTime >= sTtime && endend >= piTime) {
            // console.log("abcdefhsjdkfh==>", "true");
          } else {
            return erorShow(
              `Pick up time should be ${moment(am[0]?.startTime, [
                "h:mm a",
              ]).format("hh:mm a")} to  ${moment(am[0]?.ToTime, [
                "h:mm a",
              ]).format("hh:mm a")}`
            );
          }
        } else {
          am = activePlan
            ?.filter((item) => item?.city == city)
            ?.filter((ele) => ele?.ratePlane == "General");
        }
        if (am?.length == 0) {
          return erorShow("No any active plan");
        }
        if(airportSt=="true"){
          // alert("a")
          let an=am[0]?.vaehicleCate;
          if(an?.filter((ele)=>ele?.PickPerkmPrice!==0)?.length==0) return erorShow("No service provides this route")
        }else if(airportSt=="false"){
          // alert("b")
            let an=am[0]?.vaehicleCate
            an=an?.filter((ele)=>ele?.DropPerkmPrice!==0)
            // console.log("shjhd",an)
          if(an?.length==0) return erorShow("No service provides this route")
        }
        // alert(distance)
        if (distance) {
          let km=0
          if(am[0]?.baseKm>distance){
            km=am[0]?.baseKm
          }else{
            km=distance
          }
          history("/search", {
            state: {
              from: from,
              to: to,
              pickupdate: pickupdate,
              pickuptime: pickuptime,
              way: "Airport",
              km: km,
              orgLat: orginlat,
              orgLng: orginlon,
              distLng: distlon,
              distLat: distlat,
              packagedata: am[0],
              isSold: isSold2,
              city: city,
              parseData: parseData,
              airport: airportSt,
            },
          });
          return window.location.reload();
        }
      }
      if (local) {
        setab("");
        if (!from) return erorShow("Please fill the blank");
        if (!km) return erorShow("Please fill the blank");
        handleCancel();
    
        history("/search", {
          state: {
            from: localAd,
            to: "Rental Local",
            pickupdate: pickupdate,
            pickuptime: pickuptime,
            way: "Local",
            km: km,
            orgLat: nolat,
            orgLng: nolng,
            hr: hrs,
            package: packageD,
            distLat: droplat,
            distLng: droplong,
            pack: localPack,
          },
        });
        return window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  let [count, setcount] = useState(1);
  useEffect(() => {
    if (state) {
      if (state?.way == "Round Trip") {
        setcount(count + 1);
      }
      if (state?.km > 250) {
        setcount(count + 1);
      }
    }
  }, []);

  const [activePlan, setActivePlan] = useState([]);

  const getRatePlane = async () => {
    try {
      let res = await axios.get(
        "https://goeasycab.info/api/admin/getActiveAirportPlane"
      );
      if (res.status === 200) {
        setActivePlan(res.data.success);
      }
    } catch (error) {
      console.log(error);
      erorShow(error.response.data.error);
    }
  };
  useEffect(() => {
    getRatePlane();
  }, []);
  let picdate = state?.pickupdate?.split("/").reverse();
  let pick2 = parseInt(picdate?.join(""));
  console.log("isShold", isSold, city);

  console.log(
    "check local ",
    parseInt(localPack?.to?.split("-")?.join()) >= pick2,
    localPack?.from?.split("-")?.join("")
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // Simulate some asynchronous operation (e.g., fetching data) here.
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulated 2 seconds delay
  },[])
  return (
    <>
    
      <section className="banner-section">
        <div className="vehicle">
          <Container>
            <div className="vehicle_hero">
              <div className="vechile-0">
                <p>
                  {state?.from} {">"}
                  {state?.to}
                  {state?.way == "Round Trip" ? (
                    <>
                      {">"}
                      {state?.from}
                    </>
                  ) : (
                    ""
                  )}{" "}
                  <span>&#10088;{state?.way}&#10089;</span>{" "}
                </p>
              </div>
              <div className="vechile-0 mf">
                <div className="vechile-date">
                  <div>Pickup:</div>
                  <div>{state?.pickupdate}</div>
                </div>
                <div className="vechile-time">
                  <div>Time:</div>
                  <div>
                    {moment(state?.pickuptime, ["h:mm a"]).format("hh:mm a")}
                  </div>
                </div>
                {state?.retundate ? (
                  <>
                    {" "}
                    |{" "}
                    <div className="vechile-date">
                      <div>Return date:</div>
                      <div>{state?.retundate}</div>
                    </div>
                    <div className="vechile-time">
                      <div> Return time:</div>
                      <div>
                        {moment(state?.returntime, ["h:mm a"]).format(
                          "hh:mm a"
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="vechile-0">
                <div className=" text-center mb-3">
                  <Button
                    className="hvr-shutter-out-horizontal"
                    onClick={showModal}
                  >
                    Update Search
                  </Button>
                </div>
              </div>
            </div>
            <Cupon/>

            {/*Out station Cab */}
            {state?.way !== "Airport" && state?.way !== "Local" ? (
              <>
                {isSold == false ? (
                  <>
                    {ActiveRoute?.filter(
                      (ele) =>
                        ele.from == state?.from &&
                        ele.to == state.to &&
                        ele.roundTrip == rountTrip
                    )?.filter((ele) => {
                      // console.log("amit",ele.ToDate);
                      let startd = ele?.fromDate?.split("/")?.reverse();
                      let date = ele?.ToDate?.split("/")?.reverse();
                      // console.log("abd",parseInt(date.join("")))
                      let end = parseInt(date?.join(""));
                      let start = parseInt(startd?.join(""));
                      return end >= pick2 && start <= pick2;
                    }).length !== 0 ? (
                      <>
                        {ActiveRoute?.filter(
                          (ele) =>
                            ele.from == state?.from &&
                            ele.to == state.to &&
                            ele.roundTrip == rountTrip
                        )
                          ?.filter((ele) => {
                            // console.log("amit",ele.ToDate);
                            let startd = ele?.fromDate?.split("/")?.reverse();
                            let date = ele?.ToDate?.split("/")?.reverse();
                            // console.log("abd",parseInt(date.join("")))
                            let end = parseInt(date?.join(""));
                            let start = parseInt(startd?.join(""));
                            return end >= pick2 && start <= pick2;
                          })
                          ?.map((item) => {
                            return (
                              <section className="banner-section">
                                <div className="update-search">
                                  <div className="left-cb">
                                    <div className="cav-details">
                                      <div className="cav-deatils-0">
                                        {item?.vechicleId?.vehicleImage ? (
                                          <a
                                            href={`https://goeasycab.info/Vehicle/${item?.vechicleId?.vehicleImage}`}
                                            target="_blank"
                                          >
                                            <img
                                              src={`https://goeasycab.info/Vehicle/${item?.vechicleId?.vehicleImage}`}
                                              alt="not-found"
                                              className="image-thub"
                                            />
                                          </a>
                                        ) : (
                                          <img
                                            src="../Image/car-1.png"
                                            alt="not-found"
                                            className="image-thub"
                                          />
                                        )}
                                      </div>
                                      <div className="cab-full-details">
                                        <h5>{item?.vechicleId?.category}</h5>
                                        <ul className="space-df mb-4">
                                          <li> {item.vehicleModel}</li>
                                          <li>|</li>
                                          <li>{item?.vechicleId?.isAc}</li>
                                          <li>|</li>
                                          <li>
                                            {item?.vechicleId?.seatAv} Seats
                                          </li>
                                        </ul>
                                        <ul className="mb-4">
                                          <li>
                                            {state?.way == "Round Trip"
                                              ? state?.km
                                              : state?.km}{" "}
                                            kms included
                                          </li>

                                          <li>
                                            ₹{item?.extraFair}/km after{" "}
                                            {state?.km} kms
                                          </li>
                                          <li>
                                            {item?.vechicleId?.luggage} Luggage
                                            Bag
                                          </li>
                                          {/* <li>₹20/km after 27 kms</li> */}
                                          <li>
                                            Free Cancellation till{" "}
                                            {item?.vechicleId?.cancelTime} of
                                            departure
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-cb">
                                    <div className="price mb-2">
                                      <span className="main-price">
                                        {" "}
                                        ₹
                                        {state?.way == "Round Trip"
                                          ? (item?.driverBata*day) +
                                            state.km * item?.perkmprice +
                                            Math.round(
                                              ((item?.driverBata*day) +
                                                state.km * item?.perkmprice) *
                                                ((gst?.Cgst + gst?.Sgst) / 100)
                                            )
                                          : 
                                          ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax)) +
                                            Math.round(
                                              (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                ((gst?.Cgst + gst?.Sgst) / 100)
                                            )}
                                      </span>

                                      <span className="main-price-0 px-2">
                                        {" "}
                                        ₹
                                        {state?.way == "Round Trip"
                                          ? (item?.driverBata*day) +
                                            state.km * item?.perkmprice +
                                            Math.round(
                                              ((item?.driverBata*day) +
                                                state.km * item?.perkmprice) *
                                                ((gst?.Cgst + gst?.Sgst) / 100)
                                            ) -
                                            Math.round(
                                              ((item?.driverBata*day) +
                                                state.km * item?.perkmprice) *
                                                (item?.discoont / 100)
                                            )
                                          : 
                                            ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))+
                                            Math.round(
                                              (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                ((gst?.Cgst + gst?.Sgst) / 100)
                                            ) -
                                            Math.round(
                                              (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                (item?.discoont / 100)
                                            )}
                                      </span>
                                    </div>
                                    <p>
                                      {" "}
                                      {state?.way == "Round Trip" ? (
                                        "inc. of Gst"
                                      ) : (
                                         "inc. of tolls and Gst" 
                                      )}
                                    </p>
                                    {item?.isSold == true &&
                                    parseInt(
                                      item?.soldEnd?.split("-")?.join("")
                                    ) >= pick2 &&
                                    parseInt(
                                      item?.soldStart?.split("-")?.join("")
                                    ) <= pick2 ? (
                                      <Button
                                        className="hvr-shutter-out-horizontal"
                                        style={{ backgroundColor: "#f5e173" }}
                                      >
                                        Sold Out
                                      </Button>
                                    ) : (
                                      <div className=" text-center mt-3">
                                        <Link
                                          to="/customerDetails"
                                          state={{
                                            etraFair: item?.extraFair,
                                            perKmPr: item?.perkmprice,
                                            tollCharge:item?.totalTol,
                                            item: item?.vechicleId,
                                            item1: item,
                                            state: state,
                                            parkingCharge:item?.parkingCharge,
                                            discount:
                                              state?.way == "Round Trip"
                                                ? Math.round(
                                                    ((item?.driverBata*day) +
                                                      state.km *
                                                        item?.perkmprice) *
                                                      (item?.discoont / 100)
                                                  )
                                                : Math.round(
                                                    (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                      (item?.discoont / 100)
                                                  ),
                                            gst:
                                              state?.way == "Round Trip"
                                                ? Math.round(
                                                    ((item?.driverBata*day) +
                                                      state.km *
                                                        item?.perkmprice) *
                                                      ((gst?.Cgst + gst?.Sgst) /
                                                        100)
                                                  )
                                                : Math.round(
                                                    (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                      ((gst?.Cgst + gst?.Sgst) /
                                                        100)
                                                  ),
                                            totalAmount:
                                              state?.way == "Round Trip"
                                                ? (item?.driverBata *day)+
                                                    
                                                  (state.km * item?.perkmprice) +
                                                  Math.round(
                                                    ((item?.driverBata*day) +
                                                      (state.km *
                                                        item?.perkmprice)) *
                                                      ((gst?.Cgst + gst?.Sgst) /
                                                        100)
                                                  ) -
                                                  Math.round(
                                                    ((item?.driverBata*day) +
                                                      state.km *
                                                        item?.perkmprice) *
                                                      (item?.discoont / 100)
                                                  )
                                                :
                                                ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))+
                                                  Math.round(
                                                    (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                      ((gst?.Cgst + gst?.Sgst) /
                                                        100)
                                                  ) -
                                                  Math.round(
                                                    (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                      (item?.discoont / 100)
                                                  ),
                                          }}
                                        >
                                          <Button className="hvr-shutter-out-horizontal">
                                            Book Now
                                          </Button>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </section>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {ActiveRoute?.filter(
                          (ele) =>
                            ele.from == state?.from &&
                            ele.to == state.to &&
                            ele.roundTrip == rountTrip &&
                            ele.ratePlane == "General"
                        )?.map((item) => {
                          return (
                            <section className="banner-section">
                              <div className="update-search">
                                <div className="left-cb">
                                  <div className="cav-details">
                                    <div className="cav-deatils-0">
                                      {item?.vechicleId?.vehicleImage ? (
                                        <a
                                          href={`https://goeasycab.info/Vehicle/${item?.vechicleId?.vehicleImage}`}
                                          target="_blank"
                                        >
                                          <img
                                            src={`https://goeasycab.info/Vehicle/${item?.vechicleId?.vehicleImage}`}
                                            alt="not-found"
                                            className="image-thub"
                                          />
                                        </a>
                                      ) : (
                                        <img
                                          src="../Image/car-1.png"
                                          alt="not-found"
                                          className="image-thub"
                                        />
                                      )}
                                    </div>
                                    <div className="cab-full-details">
                                      <h5>{item?.vechicleId?.category}</h5>
                                      <ul className="space-df mb-4">
                                        <li> {item.vehicleModel}</li>
                                        <li>|</li>
                                        <li>{item?.vechicleId?.isAc}</li>
                                        <li>|</li>
                                        <li>
                                          {item?.vechicleId?.seatAv} Seats
                                        </li>
                                      </ul>
                                      <ul className="mb-4">
                                        <li>
                                          {state?.way == "Round Trip"
                                            ? state?.km
                                            : state?.km}{" "}
                                          kms included
                                        </li>

                                        <li>
                                          ₹{item?.extraFair}/km after{" "}
                                          {state?.km} kms
                                        </li>
                                        <li>
                                          {item?.vechicleId?.luggage} Luggage
                                          Bag
                                        </li>
                                        {/* <li>₹20/km after 27 kms</li> */}
                                        <li>
                                          Free Cancellation till{" "}
                                          {item?.vechicleId?.cancelTime} of
                                          departure
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-cb">
                                  <div className="price mb-2">
                                    <span className="main-price">
                                      {" "}
                                      ₹
                                      {state?.way == "Round Trip"
                                        ? (item?.driverBata*day) +
                                          (state.km * item?.perkmprice) +
                                          Math.round(
                                            ((item?.driverBata*day) +
                                              (state.km * item?.perkmprice)) *
                                              ((gst?.Cgst + gst?.Sgst) / 100)
                                          )
                                        : 
                                        ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))+
                                          Math.round(
                                            (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                              ((gst?.Cgst + gst?.Sgst) / 100)
                                          )}
                                    </span>

                                    <span className="main-price-0 px-2">
                                      {" "}
                                      ₹
                                      {state?.way == "Round Trip"
                                        ? (item?.driverBata*day) +
                                          (state.km * item?.perkmprice) +
                                          Math.round(
                                            ((item?.driverBata*day) +
                                              state.km * item?.perkmprice) *
                                              ((gst?.Cgst + gst?.Sgst) / 100)
                                          ) -
                                          Math.round(
                                            ((item?.driverBata*day) +
                                             ( state.km * item?.perkmprice)) *
                                              (item?.discoont / 100)
                                          )
                                        : 
                                        ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))+
                                          Math.round(
                                            (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                              ((gst?.Cgst + gst?.Sgst) / 100)
                                          ) -
                                          Math.round(
                                            (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                              (item?.discoont / 100)
                                          )}
                                    </span>
                                  </div>
                                  <p>
                                    {" "}
                                    {state?.way == "Round Trip" ? (
                                      "inc. of Gst"
                                    ) : (
                                    
                                       
                                     "inc. of tolls and Gst"
                                   
                                    )}
                                  </p>
                                  {item?.isSold == true &&
                                  parseInt(
                                    item?.soldEnd?.split("-")?.join("")
                                  ) >= pick2 &&
                                  parseInt(
                                    item?.soldStart?.split("-")?.join("")
                                  ) <= pick2 ? (
                                    <Button
                                      className="hvr-shutter-out-horizontal"
                                      style={{ backgroundColor: "#f5e173" }}
                                    >
                                      Sold Out
                                    </Button>
                                  ) : (
                                    <div className=" text-center mt-3">
                                      <Link
                                        to="/customerDetails"
                                        state={{
                                          etraFair: item?.extraFair,
                                          perKmPr: item?.perkmprice,
                                          tollCharge:item?.totalTol,
                                          item: item.vechicleId,
                                          item1: item,
                                          state: state,
                                          parkingCharge:item?.parkingCharge,
                                          discount:
                                            state?.way == "Round Trip"
                                              ? Math.round(
                                                  ((item?.driverBata*day) +
                                                    (state.km *
                                                      item?.perkmprice)) *
                                                    (item?.discoont / 100)
                                                )
                                              : Math.round(
                                                  (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                    (item?.discoont / 100)
                                                ),
                                          gst:
                                            state?.way == "Round Trip"
                                              ? Math.round(
                                                  ((item?.driverBata*day) +
                                                    (state.km *
                                                      item?.perkmprice)) *
                                                    ((gst?.Cgst + gst?.Sgst) /
                                                      100)
                                                )
                                              : Math.round(
                                                  (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                    ((gst?.Cgst + gst?.Sgst) /
                                                      100)
                                                ),
                                          totalAmount:
                                            state?.way == "Round Trip"
                                              ? (item?.driverBata*day) +
                                                (state.km * item?.perkmprice) +
                                                Math.round(
                                                  ((item.driverBata*day) +
                                                    (state.km *
                                                      item?.perkmprice)) *
                                                    ((gst?.Cgst + gst?.Sgst) /
                                                      100)
                                                ) -
                                                Math.round(
                                                  ((item?.driverBata*day) +
                                                    (state.km *
                                                      item?.perkmprice)) *
                                                    (item?.discoont / 100)
                                                )
                                              : 
                                              ((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))+
                                                Math.round(
                                                  (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                    ((gst?.Cgst + gst?.Sgst) /
                                                      100)
                                                ) -
                                                Math.round(
                                                  (((state.km * item?.perkmprice)+Number(item?.totalTol)+Number(item?.parkingCharge)+Number(item?.driverBata)+Number(item?.stateTax))) *
                                                    (item?.discoont / 100)
                                                ),
                                        }}
                                      >
                                        <Button className="hvr-shutter-out-horizontal">
                                          Book Now
                                        </Button>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </section>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <div
                    className="grwo"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="gfw"
                      style={{
                        background: "#fbcc30",
                        padding: "30px 100px",
                        color: "white",
                        borderRadius: "25px",
                        fontSize: "25px",
                        fontWeight: "700",
                      }}
                    >
                      <a href="/" style={{ color: "white" }}>
                        Sold Out
                      </a>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

            {state?.way == "Airport" ? (
              <>
                {isSold == false ? (
                  <>
                    {allvehicle
                      ?.filter(
                        (ele) =>
                          ele.tripType?.toLowerCase() ==
                          state?.way?.toLowerCase()
                      )
                      ?.map((item) => {
                        if(outstationpack?.vaehicleCate
                          ?.filter(
                            (ele) =>
                              ele.category?.toLowerCase() ==
                              item?.category?.toLowerCase()
                          ).length!==0){
                            return (
                              <>
                              <section className="banner-section">
                                <div className="update-search">
                                  <div className="left-cb">
                                    <div className="cav-details">
                                      <div className="cav-deatils-0">
                                        {item?.vehicleImage ? (
                                          <a
                                            href={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                                            target="_blank"
                                          >
                                            <img
                                              src={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                                              alt="not-found"
                                              className="image-thub"
                                            />
                                          </a>
                                        ) : (
                                          <img
                                            src="../Image/car-1.png"
                                            alt="not-found"
                                            className="image-thub"
                                          />
                                        )}
                                      </div>
                                      <div className="cab-full-details">
                                        <h5>{item?.category}</h5>
                                        <ul className="space-df mb-4">
                                          <li>{item.vehModel}</li>
                                          <li>|</li>
                                          <li>{item?.isAc}</li>
                                          <li>|</li>
                                          <li>{item?.seatAv} Seats</li>
                                        </ul>
                                        <ul className="mb-4">
                                          <li>{state?.km} kms included</li>
                                          <li>{item?.luggage} Luggage Bag</li>
                                          {outstationpack?.vaehicleCate
                                            ?.filter(
                                              (ele) =>
                                                ele.category?.toLowerCase() ==
                                                item?.category?.toLowerCase()
                                            )
                                            ?.map((ab) => {
                                              return (
                                                <li>
                                                  {state?.airport == "true" ? (
                                                    <>
                                                      {" "}
                                                      ₹{ab?.PickPerkmExtra}/km after{" "}
                                                      {state?.km} kms
                                                    </>
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      ₹{ab?.DropPerkmExtra}/km after{" "}
                                                      {state?.km} kms
                                                    </>
                                                  )}
                                                </li>
                                              );
                                            })}
    
                                          <li>
                                            Free Cancellation till{" "}
                                            {item?.cancelTime} of departure
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {state?.airport == "true" ? (
                                    <>
                                      {outstationpack?.vaehicleCate?.filter(
                                        (ele) =>
                                          ele?.category?.toLowerCase() ==
                                            item?.category?.toLowerCase() &&
                                          ele?.PickPerkmPrice != 0
                                      )?.length != 0 ? (
                                        <>
                                          {outstationpack?.vaehicleCate
                                            ?.filter(
                                              (ele) =>
                                                ele?.category?.toLowerCase() ==
                                                item?.category?.toLowerCase()
                                            )
                                            ?.map((ab) => {
                                              return (
                                                <div className="right-cb">
                                                  {state?.airport == "true" ? (
                                                    <div className="price mb-2">
                                                      <span className="main-price">
                                                        {" "}
                                                        ₹
                                                        {((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          )}
                                                      </span>
    
                                                      <span className="main-price-0 px-2">
                                                        {" "}
                                                        ₹
                                                        {((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge)+
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ) -
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                          )}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="price mb-2">
                                                      <span className="main-price">
                                                        {" "}
                                                        ₹
                                                        { ((ab?.DropPerkmPrice *state?.km )+outstationpack?.tollCharge+outstationpack?.parkingCharge)+
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice *state?.km )+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          )}
                                                      </span>
    
                                                      <span className="main-price-0 px-2">
                                                        {" "}
                                                        ₹
                                                        {((ab?.DropPerkmPrice *state?.km )+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice *state?.km )+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ) -
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice *state?.km )+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                          )}
                                                      </span>
                                                    </div>
                                                  )}
    
                                                  <p
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    inc. of airport tolls
                                                    <br /> and taxes
                                                  </p>
                                                  {state?.airport == "true" ? (
                                                    <>
                                                      <div className=" text-center mt-3">
                                                        <Link
                                                          to="/customerDetails"
                                                          state={{
                                                            etraFair:
                                                              ab?.PickPerkmExtra,
                                                              tollCharge:
                                                              outstationpack?.tollCharge,
                                                              parkingCharge:
                                                              outstationpack?.parkingCharge,
                                                            perKmPr:
                                                              ab?.PickPerkmPrice,
                                                            item: item,
                                                            packageItem: ab,
                                                            state: state,
                                                            discount: 
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                (ab?.discount / 100)
                                                            ,
                                                            gst: ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                ((gst?.Cgst +
                                                                  gst?.Sgst) /
                                                                  100)
                                                            ,
                                                            totalAmount:
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                              Math.round(
                                                                ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                  ((gst?.Cgst +
                                                                    gst?.Sgst) /
                                                                    100)
                                                              ) -
                                                              Math.round(
                                                                ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                  (ab?.discount /
                                                                    100)
                                                              ),
                                                          }}
                                                        >
                                                          <Button className="hvr-shutter-out-horizontal">
                                                            Book Now
                                                          </Button>
                                                        </Link>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div className=" text-center mt-3">
                                                      <Link
                                                        to="/customerDetails"
                                                        state={{
                                                          etraFair:
                                                            ab?.DropPerkmExtra,
                                                          perKmPr:
                                                            ab?.DropPerkmPrice,
                                                            tollCharge:
                                                            outstationpack?.tollCharge,
                                                            parkingCharge:
                                                            outstationpack?.parkingCharge,
                                                          item: item,
                                                          packageItem: ab,
                                                          state: state,
                                                          discount: 
                                                          ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                         ,
                                                          gst: 
                                                            ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ,
                                                          totalAmount:
                                                          ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                            Math.round(
                                                              ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                ((gst?.Cgst +
                                                                  gst?.Sgst) /
                                                                  100)
                                                            ) -
                                                            Math.round(
                                                              ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                (ab?.discount / 100)
                                                            ),
                                                        }}
                                                      >
                                                        <Button className="hvr-shutter-out-horizontal">
                                                          Book Now
                                                        </Button>
                                                      </Link>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            })}
                                        </>
                                      ) : (
                                        <div className="vechile-0">
                                          <div className=" text-center mb-3">
                                            <Button
                                              className="hvr-shutter-out-horizontal"
                                              onClick={showModal}
                                            >
                                              Not Availabale
                                            </Button>
                                          </div>
                                        </div>
                                      )}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {outstationpack?.vaehicleCate?.filter(
                                        (ele) =>
                                          ele?.category?.toLowerCase() ==
                                            item?.category?.toLowerCase() &&
                                          ele?.DropPerkmPrice != 0
                                      )?.length != 0 ? (
                                        <>
                                          {outstationpack?.vaehicleCate
                                            ?.filter(
                                              (ele) =>
                                                ele?.category?.toLowerCase() ==
                                                item?.category?.toLowerCase()
                                            )
                                            ?.map((ab) => {
                                              return (
                                                <div className="right-cb">
                                                  {state?.airport == "true" ? (
                                                    <div className="price mb-2">
                                                      <span className="main-price">
                                                        {" "}
                                                        ₹
                                                        { ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge)+
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          )}
                                                      </span>
    
                                                      <span className="main-price-0 px-2">
                                                        {" "}
                                                        ₹
                                                        {((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge)+
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ) -
                                                          Math.round(
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                          )}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="price mb-2">
                                                      <span className="main-price">
                                                        {" "}
                                                        ₹
                                                        {((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          )}
                                                      </span>
    
                                                      <span className="main-price-0 px-2">
                                                        {" "}
                                                        ₹
                                                        {((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ) -
                                                          Math.round(
                                                            ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                          )}
                                                      </span>
                                                    </div>
                                                  )}
    
                                                  <p
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    inc. of airport tolls
                                                    <br /> and taxes
                                                  </p>
                                                  {state?.airport == "true" ? (
                                                    <>
                                                      <div className=" text-center mt-3">
                                                        <Link
                                                          to="/customerDetails"
                                                          state={{
                                                            etraFair:
                                                              ab?.PickPerkmExtra,
                                                            perKmPr:
                                                              ab?.PickPerkmPrice,
                                                            item: item,
                                                            tollCharge:
                                                            outstationpack?.tollCharge,
                                                            parkingCharge:
                                                            outstationpack?.parkingCharge,
                                                            packageItem: ab,
                                                            state: state,
                                                            discount: 
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                (ab?.discount / 100)
                                                           ,
                                                            gst: 
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                ((gst?.Cgst +
                                                                  gst?.Sgst) /
                                                                  100)
                                                            ,
                                                            totalAmount:
                                                            ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                              Math.round(
                                                                ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                  ((gst?.Cgst +
                                                                    gst?.Sgst) /
                                                                    100)
                                                              ) -
                                                              Math.round(
                                                                ((ab?.PickPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                  (ab?.discount /
                                                                    100)
                                                              ),
                                                          }}
                                                        >
                                                          <Button className="hvr-shutter-out-horizontal">
                                                            Book Now
                                                          </Button>
                                                        </Link>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div className=" text-center mt-3">
                                                      <Link
                                                        to="/customerDetails"
                                                        state={{
                                                          etraFair:
                                                            ab?.DropPerkmExtra,
                                                          perKmPr:
                                                            ab?.DropPerkmPrice,
                                                          item: item,
                                                          tollCharge:
                                                          outstationpack?.tollCharge,
                                                          parkingCharge:
                                                          outstationpack?.parkingCharge,
                                                          packageItem: ab,
                                                          state: state,
                                                          discount: 
                                                          ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              (ab?.discount / 100)
                                                          ,
                                                          gst: 
                                                          ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                              ((gst?.Cgst +
                                                                gst?.Sgst) /
                                                                100)
                                                          ,
                                                          totalAmount:
                                                          ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) +
                                                            Math.round(
                                                              ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                ((gst?.Cgst +
                                                                  gst?.Sgst) /
                                                                  100)
                                                            ) -
                                                            Math.round(
                                                              ((ab?.DropPerkmPrice * state?.km)+outstationpack?.tollCharge+outstationpack?.parkingCharge) *
                                                                (ab?.discount / 100)
                                                            ),
                                                        }}
                                                      >
                                                        <Button className="hvr-shutter-out-horizontal">
                                                          Book Now
                                                        </Button>
                                                      </Link>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            })}
                                        </>
                                      ) : (
                                        <div className="vechile-0">
                                          <div className=" text-center mb-3">
                                            <Button
                                              className="hvr-shutter-out-horizontal"
                                              onClick={showModal}
                                            >
                                              Not Availabale
                                            </Button>
                                          </div>
                                        </div>
                                      )}{" "}
                                    </>
                                  )}
                                </div>
                              </section>
                              </>
                            );
                          }
                    
                      })}
                  </>
                ) : (
                  <>
                    <div
                      className="grwo"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        className="gfw"
                        style={{
                          background: "#fbcc30",
                          padding: "30px 100px",
                          color: "white",
                          borderRadius: "25px",
                          fontSize: "25px",
                          fontWeight: "700",
                        }}
                      >
                        <a href="/" style={{ color: "white" }}>
                          Sold Out
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            {state?.way == "Local" ? (
              <>
                {localPack?.isSold == true &&
                parseInt(localPack?.to?.split("-")?.join("")) >= pick2 &&
                parseInt(localPack?.from?.split("-")?.join("")) <= pick2 ? (
                  <div
                    className="grwo"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="gfw"
                      style={{
                        background: "#fbcc30",
                        padding: "30px 100px",
                        color: "white",
                        borderRadius: "25px",
                        fontSize: "25px",
                        fontWeight: "700",
                      }}
                    >
                      <a href="/" style={{ color: "white" }}>
                        Sold Out
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    {allvehicle
                      ?.filter(
                        (ele) =>
                          ele.tripType?.toLowerCase() ==
                          state?.way?.toLowerCase()
                      )
                      ?.map((item) => {
                        return (
                          <section className="banner-section">
                            <div className="update-search">
                              <div className="left-cb">
                                <div className="cav-details">
                                  <div className="cav-deatils-0">
                                    {item?.vehicleImage ? (
                                      <a
                                        href={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={`https://goeasycab.info/Vehicle/${item?.vehicleImage}`}
                                          alt="not-found"
                                          className="image-thub"
                                        />
                                      </a>
                                    ) : (
                                      <img
                                        src="../Image/car-1.png"
                                        alt="not-found"
                                        className="image-thub"
                                      />
                                    )}
                                  </div>
                                  <div className="cab-full-details">
                                    <h5>{item?.category}</h5>
                                    <ul className="space-df mb-4">
                                      <li> {item.vehModel}</li>
                                      <li>|</li>
                                      <li>{item?.isAc}</li>
                                      <li>|</li>
                                      <li>{item?.seatAv} Seats</li>
                                    </ul>
                                    <ul className="mb-4">
                                      <li>{state?.km} kms included</li>
                                      <li>{item?.luggage} Luggage Bag</li>
                                      <li>
                                        ₹{item?.perKmPrice}/km after {state?.km}{" "}
                                        kms
                                      </li>
                                      <li>
                                        Free Cancellation till{" "}
                                        {item?.cancelTime} of departure
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="right-cb">
                                <div className="price mb-2">
                                  <span className="main-price">
                                    {" "}
                                    ₹
                                    {item?.basePrice +
                                      state?.package?.price +
                                      Math.round(
                                        (item?.basePrice +
                                          state?.package?.price) *
                                          ((gst?.Cgst + gst?.Sgst) / 100)
                                      )}
                                  </span>

                                  <span className="main-price-0 px-2">
                                    {" "}
                                    ₹
                                    {item?.basePrice +
                                      state?.package?.price +
                                      Math.round(
                                        (item?.basePrice +
                                          state?.package?.price) *
                                          ((gst?.Cgst + gst?.Sgst) / 100)
                                      ) -
                                      Math.round(
                                        (item?.basePrice +
                                          state?.package?.price) *
                                          (item?.discount / 100)
                                      )}
                                  </span>
                                </div>
                                {/* <p> {state?.way == "Round Trip" ? (<>{outstationpack?.totalTol ? ("inc. of tolls and Gst"):("inc. of Gst")}</> ):(<>{outstationpack?.totalTol ? ("inc. of tolls and Gst"):("inc. of Gst")}</>)}</p>
                                 */}
                                <p style={{ textAlign: "center" }}>
                                  inc. of airport tolls
                                  <br /> and taxes
                                </p>
                                <div className=" text-center mt-3">
                                  <Link
                                    to="/customerDetails"
                                    state={{
                                      etraFair: item?.perKmPrice,
                                      perKmPr: item?.perKmPrice,
                                      item: item,
                                      state: state,
                                      discount: Math.round(
                                        (item?.basePrice +
                                          state?.package?.price) *
                                          (item?.discount / 100)
                                      ),
                                      gst: Math.round(
                                        (item?.basePrice +
                                          state?.package?.price) *
                                          ((gst?.Cgst + gst?.Sgst) / 100)
                                      ),
                                      totalAmount:
                                        item?.basePrice +
                                        state?.package?.price +
                                        Math.round(
                                          (item?.basePrice +
                                            state?.package?.price) *
                                            ((gst?.Cgst + gst?.Sgst) / 100)
                                        ) -
                                        Math.round(
                                          (item?.basePrice +
                                            state?.package?.price) *
                                            (item?.discount / 100)
                                        ),
                                    }}
                                  >
                                    <Button className="hvr-shutter-out-horizontal">
                                      Book Now
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </section>
                        );
                      })}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {/* <section className="banner-section">
              <div className="update-search">
                <div className="left-cb">
                  <div className="cav-details">
                    <div className="cav-deatils-0">
                      <a href="/Image/swift.jpg">
                        <img
                          src="/Image/swift.jpg"
                          alt="not-found"
                          className="image-thub"
                        />
                      </a>
                    </div>
                    <div className="cab-full-details">
                      <h5>Indica, Swift</h5>
                      <ul className="space-df mb-4">
                        <li>Hatchback</li>
                        <li>|</li>
                        <li>AC</li>
                        <li>|</li>
                        <li>4 Seats</li>
                      </ul>
                      <ul className="mb-4">
                        <li>140 kms included</li>
                        <li>1 Luggage Bag</li>
                        <li>Free Cancellation till 2 hour of departure</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right-cb">
                  <div className="price mb-2">
                    <span className="main-price">₹2616</span>
                    <span className="main-price-0 px-2">₹2379</span>
                  </div>
                  <p>inc. of tolls and taxes</p>
                  <div className=" text-center mt-3">
                    <Button className="hvr-shutter-out-horizontal">
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </section>
            <section className="banner-section">
              <div className="update-search">
                <div className="left-cb">
                  <div className="cav-details">
                    <div className="cav-deatils-0">
                      <a href="/Image/swift.jpg">
                        <img
                          src="/Image/swift.jpg"
                          alt="not-found"
                          className="image-thub"
                        />
                      </a>
                    </div>
                    <div className="cab-full-details">
                      <h5>Indica, Swift</h5>
                      <ul className="space-df mb-4">
                        <li>Hatchback</li>
                        <li>|</li>
                        <li>AC</li>
                        <li>|</li>
                        <li>4 Seats</li>
                      </ul>
                      <ul className="mb-4">
                        <li>140 kms included</li>
                        <li>1 Luggage Bag</li>
                        <li>Free Cancellation till 2 hour of departure</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right-cb">
                  <div className="price mb-2">
                    <span className="main-price">₹2616</span>
                    <span className="main-price-0 px-2">₹2379</span>
                  </div>
                  <p>inc. of tolls and taxes</p>
                  <div className=" text-center mt-3">
                  <Link to ="/customerDetails">
                  <Button className="hvr-shutter-out-horizontal">
                      Book Now
                    </Button>
                  </Link>
                  </div>
                </div>
              </div>
            </section> */}
          </Container>
        </div>
      </section>

      {/*Edit Search Update Cabs */}

      <Modal
        title="Edit Booking"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="local-00-0">
          <div className="ver_her0">
            <ul className="ul_wrap">
              <li
                className={outStation ? "tab-active" : "null"}
                onClick={() => {
                  setoutStation(true);
                  setairport("false");
                  setlocal(false);
                }}
              >
                Outstation
              </li>
              <li
                className={airport == "true" ? "tab-active" : "null"}
                onClick={() => {
                  setoutStation(false);
                  setairport("true");
                  setlocal(false);
                }}
              >
                Airport
              </li>
              <li
                className={local ? "tab-active" : "null"}
                onClick={() => {
                  setoutStation(false);
                  setairport("false");
                  setlocal(true);
                }}
              >
                Local
              </li>
            </ul>
          </div>

          <div className="localtab tab">
            {outStation ? (
              <>
                <div className="tab-he">
                  <ul className="one-tab text-center">
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio
                        value={1}
                        onClick={() => {
                          setoneWay(true);
                          setroundTrip(false);
                        }}
                        checked={oneWay}
                      >
                        {" "}
                        One-Way{" "}
                      </Radio>
                      <Radio
                        value={2}
                        onClick={() => {
                          setoneWay(false);
                          setroundTrip(true);
                        }}
                        checked={roundTrip}
                      >
                        {" "}
                        Round trip{" "}
                      </Radio>
                    </Radio.Group>
                  </ul>
                  <div className="auto-tads">
                    {oneWay ? (
                      <>
                        <div className="js-example pb-2">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={[
                              ...new Map(
                                top100Films.map((item) => [item?.label, item])
                              ).values(),
                            ]}
                            onChange={(event, value) => {
                              console.log("event", event, value);
                              setfrom(value?.label);
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField {...params} label={from}/>
                            )}
                          />
                        </div>
                        <div className="js-example pb-2">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={[
                              ...new Map(
                                top100Films2.map((item) => [item?.label, item])
                              ).values(),
                            ]}
                            style={{ width: "100%" }}
                            onChange={(event, value) => setto(value?.label)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={to}
                              />
                            )}
                          />
                        </div>

                        <div className="feature-list mb-4">
                          <div className="hourly mb-3">
                            <div className="hourly_hero">
                              <label className="eiggyI pb-2">Pickup Date</label>
                              <div className="">
                                <DatePicker
                                  style={{ width: "60%", height: "38px" }}
                                  format="DD/MM/YYYY"
                                  type="input-icon"
                                  minDate={new Date()}
                                  value={pickupdate}
                                  onChange={(date) =>
                                    setpickupdate(date.format("DD/MM/YYYY"))
                                  }
                                />
                              </div>
                            </div>
                            <div className="hourly_hero">
                              <label className="eiggyI pb-2">Pickup Time</label>
                              <div>
                                <select
                                  name="txtStartTime"
                                  className="select_hero-0"
                                  onChange={(e) =>
                                    setpickuptime(e.target.value)
                                  }
                                >
                                  {pickuptime ? (
                                    <option>
                                      {" "}
                                      {moment(pickuptime, ["h:mm A"]).format(
                                        "hh:mm a"
                                      )}
                                    </option>
                                  ) : (
                                    <option>Select Time</option>
                                  )}
                                  {am1?.map((item, i) => {
                                    return (
                                      <option value={item?.time}>
                                        {moment(item?.time, ["h:mm A"]).format(
                                          "hh:mm a"
                                        )}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {roundTrip ? (
                          <>
                            <div className="js-example pb-2">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                  ...new Map(
                                    top100Films.map((item) => [
                                      item?.label,
                                      item,
                                    ])
                                  ).values(),
                                ]}
                                onChange={(event, value) => {
                                  console.log("event", event, value);
                                  setfrom(value?.label);
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={from}
                                  />
                                )}
                              />
                            </div>
                            <div className="js-example pb-2">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                  ...new Map(
                                    top100Films2.map((item) => [
                                      item?.label,
                                      item,
                                    ])
                                  ).values(),
                                ]}
                                onChange={(event, value) => setto(value?.label)}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={to}
                                  />
                                )}
                              />
                            </div>

                            <div className="feature-list mb-4">
                              <div className="hourly mb-3">
                                <div className="hourly_hero">
                                  <label className="eiggyI pb-2">
                                    Pickup Date
                                  </label>
                                  <div className="">
                                    <DatePicker
                                      style={{ width: "60%", height: "38px" }}
                                      format="DD/MM/YYYY"
                                      type="input-icon"
                                      minDate={new Date()}
                                      value={pickupdate}
                                      onChange={(date) =>
                                        setpickupdate(date.format("DD/MM/YYYY"))
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="hourly_hero">
                                  <label className="eiggyI pb-2">
                                    Pickup Time
                                  </label>
                                  <div>
                                    <select
                                      name="txtStartTime"
                                      className="select_hero-0"
                                      onChange={(e) =>
                                        setpickuptime(e.target.value)
                                      }
                                    >
                                      {pickuptime ? (
                                        <option>
                                          {" "}
                                          {moment(pickuptime, [
                                            "h:mm A",
                                          ]).format("hh:mm a")}
                                        </option>
                                      ) : (
                                        <option>Select Time</option>
                                      )}
                                      {am1?.map((item, i) => {
                                        return (
                                          <option value={item?.time}>
                                            {moment(item?.time, [
                                              "h:mm A",
                                            ]).format("hh:mm a")}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Return */}
                            <div className="hourly mb-3">
                              <div className="hourly_hero">
                                <label className="eiggyI pb-2">
                                  Return Date
                                </label>
                                <div className="">
                                  <DatePicker
                                    style={{ width: "60%", height: "38px" }}
                                    format="DD/MM/YYYY"
                                    type="input-icon"
                                    minDate={new Date()}
                                    value={retundate}
                                    onChange={(date) =>
                                      setretundate(date.format("DD/MM/YYYY"))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="hourly_hero">
                                <label className="eiggyI pb-2">
                                  Return Time
                                </label>
                                <div>
                                  <select
                                    name="txtStartTime"
                                    className="select_hero-0"
                                    onChange={(e) =>
                                      setreturntime(e.target.value)
                                    }
                                  >
                                    {returntime ? (
                                      <option>
                                        {" "}
                                        {moment(returntime, ["h:mm A"]).format(
                                          "hh:mm a"
                                        )}
                                      </option>
                                    ) : (
                                      <option>Select Time</option>
                                    )}
                                    {am1?.map((item, i) => {
                                      return (
                                        <option value={item?.time}>
                                          {moment(item?.time, [
                                            "h:mm A",
                                          ]).format("hh:mm a")}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {airport == "true" ? (
                  <>
                    <div className="tab-he feature">
                      <div className="feature-list mb-3">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setairportSt(e.target.value)}
                        >
                          <option>Select menu</option>
                          <option value={true}>Airport Pickup</option>
                          <option value={false}>Airport Dropoff</option>
                        </Form.Select>
                      </div>
                      <div className="feature-list mb-3">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setcity(e.target.value)}
                        >
                          <option>Select City</option>
                          {allCity?.map((ele) => {
                            return (
                              <option value={ele?.city}>{ele?.city}</option>
                            );
                          })}
                        </Form.Select>
                      </div>
                      {airportSt == "true" ? (
                        <div className="feature-list mb-3">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => HandeleAirport(e.target.value)}
                          >
                            <option>Select Airport</option>
                            {allAirpots
                              ?.filter((tem) => tem?.city == city)
                              ?.map((ele) => {
                                return (
                                  <option value={JSON.stringify(ele)}>
                                    {ele?.airportName}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="feature-list mb-3">
                        <Autocomplete2
                          apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                          placeholder={
                            airport == "true"
                              ? "Enter your drop place"
                              : "Enter your pick up place"
                          }
                          className="Googlebaba"
                          onPlaceSelected={(place) => {
                            handlePlaceChangedBcd2(place);
                          }}
                          options={{
                            // types: ["(resoins)"],
                            componentRestrictions: { country: "in" },
                            fields: [
                              "address_components",
                              "geometry",
                              "icon",
                              "name",
                            ],
                            types: ["establishment"],
                          }}
                        />
                        {airportStatus && distlat ? (
                          <DistanceMatrixService
                            options={{
                              destinations: [
                                { lat: Number(distlat), lng: Number(distlon) },
                              ],
                              origins: [
                                {
                                  lng: Number(orginlon),
                                  lat: Number(orginlat),
                                },
                              ],
                              travelMode: "DRIVING",
                            }}
                            // options={{
                            //   destinations: [{lat:Number(13.0773806), lng:77.53820429999999}],
                            //   origins: [{lng:77.7068, lat:13.1989}],
                            //   travelMode: "DRIVING",
                            // }}
                            callback={(response) => {
                              // console.log("desti dta", distlat,distlon);
                              // console.log("Amit sir", parseInt(response.rows[0].elements[0].distance?.text),response);
                              setdistance(
                                parseInt(
                                  response.rows[0].elements[0].distance?.text
                                )
                              );
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {airportSt == "false" ? (
                        <div className="feature-list mb-3">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => HandeleAirport(e.target.value)}
                          >
                            <option>Select Airport</option>
                            {allAirpots
                              ?.filter((tem) => tem?.city == city)
                              ?.map((ele) => {
                                return (
                                  <option value={JSON.stringify(ele)}>
                                    {ele?.airportName}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="feature-list mb-4">
                        <div className="hourly">
                          <div className="hourly_hero">
                            <label className="eiggyI pb-2">Pickup Date</label>
                            <div className="">
                              <DatePicker
                                style={{ width: "60%", height: "38px" }}
                                format="DD/MM/YYYY"
                                type="input-icon"
                                minDate={new Date()}
                                value={pickupdate}
                                onChange={(date) =>
                                  setpickupdate(date.format("DD/MM/YYYY"))
                                }
                              />
                            </div>
                          </div>
                          <div className="hourly_hero">
                            <label className="eiggyI pb-2">Pickup Time</label>
                            <div>
                              <select
                                name="txtStartTime"
                                className="select_hero-0"
                                onChange={(e) => setpickuptime(e.target.value)}
                              >
                                {pickuptime ? (
                                  <option>
                                    {" "}
                                    {moment(pickuptime, ["h:mm A"]).format(
                                      "hh:mm a"
                                    )}
                                  </option>
                                ) : (
                                  <option>Select Time</option>
                                )}
                                {am1?.map((item, i) => {
                                  return (
                                    <option value={item?.time}>
                                      {moment(item?.time, ["h:mm A"]).format(
                                        "hh:mm a"
                                      )}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {local ? (
                      <>
                        <div className="tab-he feature">
                          <div className="feature-list mb-3">
                            <Form.Select
                              aria-label="Default select example"
                              className="select_hero-0"
                              style={{ width: "83%" }}
                              onChange={(e) => setcity(e.target.value)}
                            >
                              <option>Select City</option>
                              {allCity?.map((ele) => {
                                return (
                                  <option value={ele?.city}>{ele?.city}</option>
                                );
                              })}
                            </Form.Select>
                          </div>
                          <div className="feature-list mb-4">
                            <div className="hourly">
                              <div className="hourly_hero">
                                <label className="eiggyI pb-2">
                                  Rent For :{" "}
                                </label>
                              </div>{" "}
                              <div className="hourly_hero">
                                <select
                                  name="txtStartTime"
                                  className="select_hero-0"
                                  style={{ width: "83%" }}
                                  onChange={(e) => {
                                    setkm(
                                      JSON.parse(e.target.value)?.PackageKm
                                    );
                                    setPdc(JSON.parse(e.target.value));
                                    sethrs(
                                      JSON.parse(e.target.value)?.PackageHr
                                    );
                                    setLocalPack(JSON.parse(e.target.value));
                                  }}
                                >
                                  <option>Select menu</option>
                                  {allpackage
                                    ?.filter((item) => item?.city == city)
                                    .map((ele) => {
                                      return (
                                        <option value={JSON.stringify(ele)}>
                                          {ele?.PackageHr} hours with{" "}
                                          {ele?.PackageKm} KM
                                        </option>
                                      );
                                    })}

                                  {/* <option value="2">8 hours with min 80 KM</option>
            <option value="2">12 hours with min 120 KM.</option> */}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="feature-list mb-3">
                            <Autocomplete1
                              apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                              placeholder="Enter your pick up place"
                              className="Googlebaba"
                              onPlaceSelected={(place) => {
                                handlePlaceChangedL(place);
                              }}
                              options={{
                                // types: ["(resoins)"],
                                componentRestrictions: { country: "in" },
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "icon",
                                  "name",
                                ],
                                types: ["establishment"],
                              }}
                            />
                          </div>
                          {/* <div className="feature-list mb-3">
                            <Autocomplete1
                              apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                              placeholder="Enter your drop place"
                              className="Googlebaba"
                              onPlaceSelected={(place) => {
                                handlePlaceToDrop(place);
                              }}
                              options={{
                                // types: ["(resoins)"],
                                componentRestrictions: { country: "in" },
                                fields: [
                                  "address_components",
                                  "geometry",
                                  "icon",
                                  "name",
                                ],
                                types: ["establishment"],
                              }}
                            />
                          </div> */}
                          <div className="feature-list mb-4">
                            <div className="hourly">
                              <div className="hourly_hero">
                                <label className="eiggyI pb-2">
                                  Pickup Date
                                </label>
                                <div className="">
                                  <DatePicker
                                    style={{ width: "60%", height: "38px" }}
                                    format="DD/MM/YYYY"
                                    type="input-icon"
                                    minDate={new Date()}
                                    value={pickupdate}
                                    onChange={(date) =>
                                      setpickupdate(date.format("DD/MM/YYYY"))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="hourly_hero">
                                <label className="eiggyI pb-2">
                                  Pickup Time
                                </label>
                                <br />
                                <select
                                  name="txtStartTime"
                                  className="select_hero-0"
                                  onChange={(e) =>
                                    setpickuptime(e.target.value)
                                  }
                                >
                                  {pickuptime ? (
                                    <option>
                                      {" "}
                                      {moment(pickuptime, ["h:mm A"]).format(
                                        "hh:mm a"
                                      )}
                                    </option>
                                  ) : (
                                    <option>Select Time</option>
                                  )}
                                  {am1?.map((item, i) => {
                                    return (
                                      <option value={item?.time}>
                                        {moment(item?.time, ["h:mm A"]).format(
                                          "hh:mm a"
                                        )}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className=" text-center mb-3">
          <Button className="hvr-shutter-out-horizontal" onClick={checkdata}>
            SEARCH CABS
          </Button>
        </div>
      </Modal>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", padding: "25px" }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">No service provides this route</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* show model */}
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open1}
        onOk={() => setModal2Open1(false)}
        onCancel={() => setModal2Open1(false)}
      >
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open1(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SearchCav;
