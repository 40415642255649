import axios from "axios";
import React, { useState } from "react";
import "../Admin/Admin.css";
// import {Link} from "react-router-dom"
function Adminlogin() {
//   const [email, setemail] = useState("");
//   const [password, setpassword] = useState("");
  const [email1, setemail1] = useState("");
  const [password1, setpassword1] = useState("");
//   const [Name, setName] = useState("");
//   const [Mobile, setMobile] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
//   const [log, setlog] = useState(true);
//   const [reg, setreg] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

//   const onclick=()=>{
//     setlog(false);
//     setreg(true);
//   }
//   const onclick1=()=>{
//     setlog(true);
//     setreg(false);
//   }
  const signin = async (e) => {
    if (!email1 | !password1) {
      alert("Please Fill All The Field");
    } else {
      try {
        const config = {
          url: "/login",
          method: "post",
          baseURL: "https://goeasycab.info/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email1,
            password: password1,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          alert("Login Success");
          sessionStorage.setItem("admin", JSON.stringify(res.data.success));
          sessionStorage.setItem("token",res.data.token);
          window.location.assign("/admin/dashbord");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };
// const Register = async () => {
//     if(!Mobile){
//         alert("MobileNo Is Missing")
//     }
//    else if(!Name){
//         alert("Name Is Missing")
//     }
//     else if(!email){
//         alert("Email Is Missing")
//     }
//     else if(!password){
//         alert("Password Is Missing")
//     }else{
//     try {
//       const config = {
//         url: "/admin/register",
//         method: "post",
//         baseURL: "https://goeasycab.info/api",
//         hearder: { "content-type": "application/json" },

//         data: {
//             mobile: Mobile,
//             name: Name,
//             email:email,
//             password: password,
//         },
//       };
//       await axios(config).then(function (res) {
//         if (res.status === 200) {
//           console.log(res.data);
//           alert("success");
//           window.location.reload("/admin/dashbord");
//         } else {
//           alert("Something Wrong");
//         }
//       });
//     } catch (error) {
//       console.log(error.response);
//     }
// }
//   };

  return (
    <div
      style={{
        backgroundImage: "url('/backc.jpg')",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        justifyContent: "left",
        display: "flex",
      }}
    >
        <p style={{ fontWeight:"bold",fontSize:20,color:"white",margin:10}}>Go Easy Do Cab</p>
      <div className="login">
       {/* {log===true?(<> */}
        <div style={{ marginTop: "30px" }}>
          <label style={{fontWeight:"bold",color:"white",marginBottom:10}}>Email ID</label>
          <br></br>
          <input
            placeholder="Email Id"
            type="email"
            className="logininput"
            onChange={(e) => setemail1(e.target.value)}
          />
          <br></br>
          <label style={{ marginTop: "10px",fontWeight:"bold",color:"white",marginBottom:10 }}>Password</label>
          <br></br>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              className="logininput"
              onChange={(e) => setpassword1(e.target.value)}
            />
            {passwordShown ? (
              <button
                onClick={togglePassword}
                style={{
                  border: "none",
                  backgroundColor: "#20789a",
                  color:"white",
                  padding: "0px 10px",
                }}
              >
                <i class="fa fa-eye-slash" aria-hidden="true" ></i>
              </button>
            ) : (
              <button
                onClick={togglePassword}
                style={{
                  border: "none",
                  backgroundColor: "#20789a",
                  color:"white",
                  padding: "0px 10px",
                }}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            )}
          </div>
          <br></br>
          {/* <Link to="/admin/dashbord"> */}
          <button className="loginbtn" onClick={()=>{signin()}}>
            Login
          </button>
          {/* </Link> */}
        </div>

        {/* <p style={{fontWeight:"bold",color:"white",marginTop:10,textAlign:'end',fontSize:15,fontFamily:"monospace"}} onClick={()=>onclick()}>Register ?</p> */}
       {/* </>):(<></>)} */}
       

        {/* {reg===true?(<>
            <div style={{ marginTop: "30px" }}>
          <label style={{fontWeight:"bold",color:"white",marginBottom:10}}>Name</label>
          <br></br>
          <input
            placeholder="Name"
            type="Name"
            className="logininput"
            onChange={(e) => setName(e.target.value)}
          />
          <br></br>
          <label style={{fontWeight:"bold",color:"white",marginBottom:10,marginTop:10}}>Mobile</label>
          <br></br>
          <input
            placeholder="MobileNo"
            type="Mobile"
            className="logininput"
            onChange={(e) => setMobile(e.target.value)}
            maxLength={10}
          />
          <br></br>
          <label style={{fontWeight:"bold",color:"white",marginBottom:10,marginTop:10}}>Email ID</label>
          <br></br>
          <input
            placeholder="Email Id"
            type="email"
            className="logininput"
            onChange={(e) => setemail(e.target.value)}
          />
          <br></br>
          <label style={{ marginTop: "10px",fontWeight:"bold",color:"white",marginBottom:10 }}>Password</label>
          <br></br>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              className="logininput"
              onChange={(e) => setpassword(e.target.value)}
            />
            {passwordShown ? (
              <button
                onClick={togglePassword}
                style={{
                  border: "none",
                  backgroundColor: "#20789a",
                  color:"white",
                  padding: "0px 10px",
                }}
              >
                <i class="fa fa-eye-slash" aria-hidden="true" ></i>
              </button>
            ) : (
              <button
                onClick={togglePassword}
                style={{
                  border: "none",
                  backgroundColor: "#20789a",
                  color:"white",
                  padding: "0px 10px",
                }}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            )}
          </div>
          <br></br>
          
          <button className="loginbtn" onClick={()=>Register()}>
            Register
          </button>
         
        </div>
        <p style={{fontWeight:"bold",color:"white",marginTop:10,textAlign:'end',fontSize:15,fontFamily:"monospace"}} onClick={()=>onclick1()}>Login ?</p>
        </>):(<></>)} */}
      </div>
    </div>
  );
}

export default Adminlogin;