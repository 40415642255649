import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import "./header.css";
import axios from "axios";
import { Modal, Button } from "antd";
import { RxCrossCircled } from "react-icons/rx";
import { BiUserCircle } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [navigation, setnavigation] = useState("relative");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const [modal2Open, setModal2Open] = useState(false);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  let customer = JSON.parse(sessionStorage.getItem("customer"));

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };
  const [openprofile, setprofile] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [moble, setmobile] = useState("");
  const [otp, setotp] = useState("");
  const [sent, setsent] = useState(false);

  const logOut = async () => {
    try {
      sessionStorage.removeItem("customer")
      alert("Successfully logout");
      window.location.assign("/")
    } catch (error) {
      console.log(error);
    }
  };

  const[acc,setacc]=useState(true);
  const[acc1,setacc1]=useState()
  const[acc2,setacc2]=useState()
  const[acc3,setacc3]=useState()
  return (
    <>
      <div className="header-0">
        <nav className="navigation">
          <a href="/" className="brand-name">
            <img
              src="/GOLOGO.jpg"
              className="roy"
              alt="logo"
              style={{
                width: "123px",
                height: "100px",
              }}
            />
          </a>

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from Heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              <li >
                <a className={`${acc ? "tab-active":""}`} onClick={()=>{
                setacc(true);
                setacc1(false);
                setacc2(false);
                setacc3(false);
              }} href="/#service">Service</a>
              </li>

              <li >
                <a className={`${acc1 ? "tab-active":""}`} onClick={()=>{
                setacc(false);
                setacc1(true);
                setacc2(false);
                setacc3(false);
              }} href="/#about">About us</a>
              </li>
              <li >
                <a className={`${acc2 ? "tab-active":""}`} onClick={()=>{
                setacc(false);
                setacc1(false);
                setacc2(true);
                setacc3(false);
              }} href="/#contact">Contact us</a>
              </li>
              <li >
                {customer ? (
                  <a style={{ display: "flex" }}>
                    {" "}
                    {customer?.profile ? (<img src={`https://goeasycab.info/Customer/${customer?.profile}`} width="40px" height="40px" style={{borderRadius:"100%" , marginRight:"5px"}}/>):( <BiUserCircle
                      style={{ color: "#fbcc30", fontSize: "30px" }}
                    />)}
                   {" "}
                    <DropdownButton
                      id="dropdown-basic-button" style={{backgroundColor:"#fbce37"}}
                      title={customer?.name}
                    >
                      <Dropdown.Item href="/Editprofile">
                        Edit Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="/View-trip">View Trip</Dropdown.Item>
                      <Dropdown.Item href="#" onClick={logOut}>Logout</Dropdown.Item>
                    </DropdownButton>
                  </a>
                ) : (
                  <Link className={`${acc3 ? "tab-active":""}`} onClick={()=>{
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(true);
                  }} to="/login">Login</Link>
                )}
              </li>
            </ul>
            {/* <ul class="dropdown-menu show" data-bs-popper="none" style={{marginLeft:"86%"}}>
              <li><a class="dropdown-item" href="https://wavescab.com/editProfile">Edit Profile</a></li>
              <li><a class="dropdown-item" href="https://wavescab.com/viewTrip">View Trip</a></li>
              <li><a class="dropdown-item lastmenu" href="javascript:signOut();">Logout</a></li>
            </ul> */}
          </div>
        </nav>
      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", padding: "25px" }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">No service provides this route</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Sign in */}

      {/* <Modal title="Login" open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1}>
       <div className="px-3">
        <Form>
          <label style={{color:'#dfaf11'}}>Email address or mobile no</label><br/>
          <input type="text" value={moble} placeholder="Email address or mobile no" className="dhfrei" onChange={(e)=>setmobile(e.target.value)}/>
          {sent ? ( <input type="text" placeholder="Enter otp" value={otp} onChange={(e)=>setotp(e.target.value)} className="dhfrei"/>):(<></>)}
         
        </Form>
        <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
          {sent ? ( <div>
            <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30",width:"40%" }}
             
              >
                Verify otp
              </Button>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30",width:"40%" }}
             
              >
                Resend otp
              </Button>
          </div>):( <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30",width:"100%" }}
             onClick={()=>setsent(true)}
              >
                Continue
              </Button>)}
        
            </div>
       </div>
      </Modal> */}
    </>
  );
}

export default Header;
