import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneOutgoing } from "react-icons/fi";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri";
import { BsPinterest, BsTwitter } from "react-icons/bs";

function Footer() {
  return (
    <>
      <div
        className="footer-mid-wrap"
        style={{
          backgroundColor: "#222",
          backgroundImage: "url('../Image/texture.png')",
          backgroundRepeat: "repeat",
          backgroundSize: " cover",
          padding: " 260px 0px 160px",
          position: " relative",
          zIndex: "1",
        }}
      >
        <div
          id="contact"
          className="based "
          style={{
            backgroundImage: "url('../Image/city-skiline.png')",
            backgroundSize: "100%",
            backgroundPosition: " bottom center",
            backgroundRepeat: " no-repeat",
            width: " 100%",
            height: " 100%",
            position: " absolute",
            left: " 0",
            bottom: " 0",
            zIndex: "-1",
            overflow: "hidden",
          }}
        >
          <div className="container">
            <div className="based -footer">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="policies">
                    <h5 style={{ color: "white" }}>Policies</h5>
                    <div className="ejwi"></div>
                    <ul className="again">
                      <li>
                        <Link to="/terms-condition">&#xbb;Terms and Conditions</Link>
                      </li>
                      <li>
                        <Link to="/user-agreement">&#xbb;User Agreement</Link>
                      </li>
                      <li>
                        <Link to="/privacypolicy">&#xbb;Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="policies">
                    <h5 style={{ color: "white" }}>Support</h5>
                    <div className="ejwi-1"></div>
                    <ul className="again">
                      <li>
                        <span className="icons-footer">
                          <FiPhoneOutgoing />
                        </span>
                        9538880063
                      </li>
                      <li>
                        <span className="icons-footer">
                          <FiPhoneOutgoing />
                        </span>
                      8660669093
                      </li>
                      <li>
                        <span className="icons-footer">
                          <HiOutlineMail />
                        </span>
                        Info@goeasydocab.com
                      </li>
                      <li>
                        <span className="icons-footer">
                          <HiOutlineMail />
                        </span>
                        Support@goeasydocab.com
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="policies">
                    <h5 style={{ color: "white" }}>Address</h5>
                    <div className="ejwi-2"></div>
                    <ul className="again">
                      <li>
                      2693, Nagarabhavi Main Rd, New Income Tax Layout, Jyothi Nagar, Maruthi Nagar, Attiguppe, Bengaluru, Karnataka 560072
                      </li>
                      <li>
                        <img
                          src="./playstores.png"
                          alt="app-logo"
                          style={{ width: "75%" }}
                        />
                      </li>
                    </ul>
                    <div className="vwgjvns">
                    <a href="https://wa.link/l5gtcr"  style={{borderColor:"green"}}>
                        <RiWhatsappFill style={{color: "green"}}/>
                      </a>
                      <a href="https://www.facebook.com/goeasydocab/" style={{borderColor:"#0d6efd"}}>
                        <FaFacebookF style={{color:"#0d6efd"}}/>
                      </a>
                      <a href="https://www.instagram.com/goeasydo.cabs/" style={{borderColor:"#dc4c4f"}}>
                        <RiInstagramFill style={{color:"#dc4c4f"}}/>
                      </a>
                      {/* <a  style={{borderColor:"#0A66C2"}}><FaLinkedin style={{color:"#0A66C2"}}/></a> */}
                      <a href="https://twitter.com/goeasydocabs" style={{borderColor:"#00acee"}}><BsTwitter style={{color:"#00acee"}}/></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="running-taxi">
            <div
              className="taxi"
              style={{ backgroundImage: "url('../Image/truck-1.png')" }}
            />
            <div
              className="taxi-2"
              style={{ backgroundImage: "url('../Image/truck-2.png')" }}
            />
            <div className="taxi-3" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
