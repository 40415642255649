import React, { useEffect, useState } from "react";
import About from "./About";
import Airports from "./Airports";
import Local from "./Local";
import OutStation from "./OutStation";
import Service from "./Service";
import axios from "axios";
import Cupon from "./Cupon"

function Home() {
  const [outStation, setoutStation] = useState("true");
  const [airport, setairport] = useState("");
  const [local, setlocal] = useState("");
  const [allBanner,setallBanner]=useState([])
  const getAllbaner=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getBanner");
      if(res.status==200){
        setallBanner(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllbaner()
  },[])
  return (
    <>
      <div
        className="banner pt-4"
        style={{
          backgroundImage: `url('https://goeasycab.info/banner/${allBanner[0]?.bannerImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "360px",
          position:"relative"
        }}
      >
        <div className="docab">{allBanner[0]?.heading}</div>
        <div className="gr kenburns-top" data-aos="zoom-in"  data-aos-duration="2000" style={{backgroundColor: "#fbcc30",color:"white"}}>{allBanner[0]?.title}</div>
      </div>
      <section className="jitotrg">
        <div className="banner-1">
          <div className="banner_hero">
            <div className="container">
              <div className="geh">
                <div className="local-00">
                  <div className="ver_her0">
                    <ul className="ul_wrap">
                      <li
                        className={outStation ? "tab-active" : "null"}
                        onClick={() => {
                          setoutStation(true);
                          setairport(false);
                          setlocal(false);
                        }}
                      >
                        Outstation
                      </li>
                      <li
                        className={airport ? "tab-active" : "null"}
                        onClick={() => {
                          setoutStation(false);
                          setairport(true);
                          setlocal(false);
                        }}
                      >
                        Airport
                      </li>
                      <li
                        className={local ? "tab-active" : "null"}
                        onClick={() => {
                          setoutStation(false);
                          setairport(false);
                          setlocal(true);
                        }}
                      >
                        Local
                      </li>
                    </ul>
                  </div>

                  <div className="localtab tab">
                    {outStation ? (
                      <>
                        <OutStation />
                      </>
                    ) : (
                      <>
                        {airport ? (
                          <>
                            <Airports />
                          </>
                        ) : (
                          <>
                            {local ? (
                              <>
                                <Local />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="car-holder">
                  <img
                    src="../Image/car-1.png"
                    alt="car"
                  
                    className="hfiru"
                   
                  />
                  <div className="shape">
                    <div
                      data-animation="slide-in-bottom"
                      data-duration="1.5s"
                      data-delay="0.3s"
                      className
                      style={{
                        animationDelay: "0.3s",
                        animationDuration: "1.5s",
                      }}
                    />
                    <div
                      data-animation="fade-in"
                      data-duration="1.5s"
                      data-delay="0.2s"
                      className
                      style={{
                        animationDelay: "0.2s",
                        animationDuration: "1.5s",
                      }}
                    />
                    <div
                      data-animation="slide-in-top"
                      data-duration="1.5s"
                      data-delay="0.5s"
                      className
                      style={{
                        animationDelay: "0.5s",
                        animationDuration: "1.5s",
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      {/* About */}
      <About />
      <Cupon/>
      {/* Service */}
      <Service />
    </>
  );
}

export default Home;
