import React, { useState } from "react";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Sidebar1() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  if (!admin) {
    window.location.assign("/admin");
  }

  const [acc, setacc] = useState(false);

  return (
    <ProSidebarProvider style={{ height: "100vh", backgroundColor: "red" }}>
      <Sidebar
        style={{
          height: "100vh",
          backgroundImage: "url('/yell1.jpg')",
          position: "sticky",
          top: "50px",
        }}
      >
        <Menu>
          <MenuItem
            component={<Link to="/admin/dashbord" />}
            style={{ color: "white", fontWeight: "bold" }}
          >
            <span className="px-2">&#x27A4;</span> Dashboard
          </MenuItem>

          {admin?.subadmin == true ? (
            <MenuItem
              component={<Link to="/admin/subadmin" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> SubAdmin
            </MenuItem>
          ) : (
            <></>
          )}
        </Menu>
        {admin?.Banner == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/banner" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Banner
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.Coupon == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Coupon" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Coupon
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.bookCab == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Bookingdetails" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> All Bookings
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.airportRoute == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/sold-out" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Airport Routes
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.AirportTriff == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Airport-rates" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Airport Tariffs
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.OutstationTariff == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Routes" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Out Station Tariffs
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.Rentel == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Packages" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Rental & Local
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.Driver == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Drivers" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Drivers
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.homeDriver == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Home-Drivers" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span>Home Drivers
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.wallet == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/driver-wallet" />}
              style={{
                color: "white",
                fontWeight: "bold",
              
              }}
            >
              <span className="px-2">&#x27A4;</span> Driver Wallet
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.category == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Vehiclecategories" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Vehicle Category
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.vehicleName == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/vchicle-name" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Vehicle Model
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {/* {admin?.vehicalModel==true  ? (<Menu>
          <MenuItem
            component={<Link to="/admin/vchicle-modal" />}
            style={{ color: "white", fontWeight: "bold" }}
          >
            <span className="px-2">&#x27A4;</span> Vehicle Model
          </MenuItem>
        </Menu>):(<></>)} */}
        {admin?.vehical == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/Vehicle" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Vehicle
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.user == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/User" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Users
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.specialDay == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/special-day" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Special days
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.gst == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/GST" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> GST
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}
        {admin?.payments == true ? (
          <Menu>
            <MenuItem
              component={<Link to="/admin/account-history" />}
              style={{ color: "white", fontWeight: "bold" }}
            >
              <span className="px-2">&#x27A4;</span> Account History
            </MenuItem>
          </Menu>
        ) : (
          <></>
        )}

        {admin?.expanse == true ? (
          <Menu
            onClick={() => {
              setacc(!acc);
            }}
            style={{ color: "white", fontWeight: "bold", paddingLeft: "20px", marginBottom: "20px", }}
          >
            <span className="px-2 mb-3">&#x27A4;</span> Expense
            {acc ? (
              <>
                <Menu>
                  <MenuItem
                    component={<Link to="/admin/expense" />}
                    style={{ color: "white", fontWeight: "bold" ,
                   }}
                  >
                    <span className="px-2">&#x27A4;</span> Expense
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/admin/expense-list" />}
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    <span className="px-2">&#x27A4;</span> Expense List
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <></>
            )}
          </Menu>
        ) : (
          <></>
        )}
      </Sidebar>
    </ProSidebarProvider>
  );
}

export default Sidebar1;
