import React from "react";
import { Form } from "react-bootstrap";
import { Space, Button } from "antd";
import DatePicker from "react-multi-date-picker";
import { TimePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useState } from "react";

import { useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import { DistanceMatrixService } from "@react-google-maps/api";
import moment from "moment";
import { RxCrossCircled } from "react-icons/rx";
import Buttonn from 'react-bootstrap/Button';
import { Modal } from "antd";
import{FaLocationCrosshairs} from "react-icons/fa6"

function Airports() {
  const [modal2Open, setModal2Open] = useState(false);

  const [errorData,setErrordata]=useState("")
  const erorShow=(data)=>{
    setErrordata(data)
    setModal2Open(true)
  }
  const [distance, setdistance] = useState(0);

  const [airport, setairport] = useState("true");
  const [airportStatus, setairportSatus] = useState("");
  const [date, setdate] = useState(moment().format("DD/MM/YYYY"));

  const [address, setaddress] = useState("");
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  var [orginlat, setorginlat] = useState();
  var [orginlon, setorginlon] = useState();
  var [nochanorlat, setnochanorlat] = useState();
  var [nochangeOrlng, setnochangeOrlng] = useState();
  var [distlat, setdistlat] = useState();
  var [distlon, setdistlon] = useState();
  var [nolat, setnolat] = useState();
  var [nolng, setnolng] = useState();
  var [samechange,setsamchange]=useState(false)
  var handlePlaceChanged = (place) => {
    let city=sessionStorage.getItem("city")
    if (place) {
   
      if(!city) return erorShow("Please select city")
      let am=place?.address_components
      am=am?.filter((ele)=>ele?.long_name==city);
      if(am?.length==0) return erorShow(`Please enter location in side ${city}`)

      setdistlat(place.geometry.location.lat());
      setnolat(place.geometry.location.lat());
      // distanceInKm(13.1989,77.7068,place.geometry.location.lat(),place.geometry.location.lng())
      setdistlon(place.geometry.location.lng());
      setnolng(place.geometry.location.lng());
      setaddress(place.name);
      setsamchange(true);
    }
  };
 
  const history = useNavigate();
  const [airportName,setairportName]=useState("")
  useEffect(() => {
    if (airport == "true") {
    
      if (address&&airportName) {
        setorginlat(nochanorlat);
        setorginlon(nochangeOrlng);
        setdistlat(nolat);
        setdistlon(nolng);
      }
      setfrom(airportName);
      setto(address);
    } else if (airport == "false") {
      setfrom(address);
      setairportSatus("Airport Dropoff");
      setto(airportName);
      if (address&&airportName) {
        setdistlat(nochanorlat);
        setdistlon(nochangeOrlng);
        setorginlat(nolat);
        setorginlon(nolng);
      }
    }
  }, [address, airport,airportName]);
  // console.log("minmum time==>", today);
  const [activePlan,setActivePlan]=useState([])
  // var da1
  const [da1,setda1]=useState()
  const [starttime,setstarttime]=useState();
  const [endtime,setendtime]=useState()

  const getRatePlane=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getActiveAirportPlane")
      if(res.status===200){
     
        setActivePlan(res.data.success)
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  }
  const [allAirpots,setAllAirports]=useState([]);
const getAirportDetails=async()=>{
  try {
    let res=await axios.get("https://goeasycab.info/api/admin/getAllAirportsDetails");
    if(res.status==200){
      setAllAirports(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
}
const [allCity,setAllcity]=useState([]);
  const getAllCity=async()=>{
    try {
      let res=await axios.get("https://goeasycab.info/api/admin/getAllAirportCity");
      if(res.status===200){
        setAllcity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getRatePlane()
    getAirportDetails()
    getAllCity();
  },[])

  const [city,setcity]=useState("");
  const [isSold,setSold]=useState("")
  const [parseData,setparseData]=useState({})
  const checkdata = async () => {
    try {
      if(!city) return erorShow("Please select city!");
      if (!address)return erorShow("Please enter your place!");
      if (!airportName)return erorShow("Please enter your place!");
      if (!pickuptime) return erorShow("Please select pick up time");
      let pickdate=(date?.split("/").reverse())
      let ab=parseInt(pickdate?.join("")) 
     
      var pickT=pickuptime?.split(":")
      let piTime=parseInt(pickT?.join(""))
     
      
      let am=activePlan?.filter((item)=>item?.city==city)?.filter((ele)=>{
        let startd=(ele?.startDate?.split("/")?.reverse())
        let date1=(ele?.ToDate?.split("/").reverse())

        let start=parseInt(startd?.join(""))
        let end=parseInt(date1?.join(""))
        return end>=ab && start<=ab
    })
   
    if(am?.length!=0){
        var timest=am[0]?.startTime?.split(":")
       let sTtime= parseInt(timest?.join(""))

        var timeEnd=am[0]?.ToTime?.split(":")
        let endend=parseInt(timeEnd?.join(""))
          // console.log("timefilter",sTtime,endend)
        if(piTime>=sTtime&& endend>=piTime){
          // console.log("abcdefhsjdkfh==>","true");
        }else{
          return erorShow(`Pick up time should be ${moment(am[0]?.startTime,["h:mm a"]).format("hh:mm a")} to  ${moment(am[0]?.ToTime,["h:mm a"]).format("hh:mm a")}`)
        }
    }else{
      am=activePlan?.filter((item)=>item?.city==city)?.filter((ele)=>ele?.ratePlane=="General")
    }
    if(am?.length==0){
      return erorShow("No any active plan")
    }
    if(airport=="true"){
      let an=am[0]?.vaehicleCate;
      if(an?.filter((ele)=>ele?.PickPerkmPrice!==0)?.length==0) return erorShow("No service provides this route")
    }else if(airport=="false"){
        let an=am[0]?.vaehicleCate
        an=an?.filter((ele)=>ele?.DropPerkmPrice!==0)
      if(an?.length==0) return erorShow("No service provides this route")
    }
      if (distance) {
        let km=0
        if(am[0]?.baseKm>distance){
          km=am[0]?.baseKm
        }else{
          km=distance
        }
        return history("/search", {
          state: {
            from: from,
            to: to,
            pickupdate: date,
            pickuptime: pickuptime,
            way: "Airport",
            km: km,
            orgLat: orginlat,
            orgLng: orginlon,
            distLng: distlon,
            distLat: distlat,
            packagedata: am[0],
            isSold:isSold,
            city:city,
            parseData:parseData,
            airport:airport

          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
 
  ];
  const [pickuptime, setpickuptime] = useState("");
  const [am,setam1]=useState([])
  useEffect(()=>{
    let today = new Date();
    if(moment(today).format("DD/MM/YYYY")==date){
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint=0
      if(later.getMinutes()<10){
        mint=`0${later.getMinutes()}`
      }else{
        mint=later.getMinutes()
      }
      let abc = `${later.getHours()}${mint}`;
     
      // console.log("ab",abc,later.getHours(),later.getMinutes());
     // setam1(alldate.filter((ele) => ele.id > parseInt(abc)));
     setam1(alldate?.filter((ele) => ele.id > parseInt(abc)));
     // setpickuptime(alldate?.filter((ele)=>ele.id>=parseInt(starttime) &&ele.id<=parseInt(endtime) )?.filter((ele) => ele.id >= parseInt(abc))[0].time)
    
     let aj=alldate?.filter((ele) => ele.id > parseInt(abc))
     setpickuptime(aj[0]?.time)
   
    }else{
      setam1(alldate)
      setpickuptime("")
  
    }
  },[date])
  useEffect(()=>{
    let today = new Date();
    if(moment(today).format("DD/MM/YYYY")==date){
      const later = new Date(today.getTime() + 2 * 60 * 60 * 1000);
      let mint=0
      if(later.getMinutes()<10){
        mint=`0${later.getMinutes()}`
      }else{
        mint=later.getMinutes()
      }
      let abc = `${later.getHours()}${mint}`;
   
    setam1(alldate?.filter((ele) => ele.id > parseInt(abc)));
    let aj=alldate?.filter((ele) => ele.id > parseInt(abc));
    setpickuptime(aj[0]?.time)
    }else{
      setam1(alldate)
      setpickuptime("")
  
    }
  },[starttime])
const [check,setcheck]=useState(false)
  const HandeleAirport=(ab)=>{
    try {
     
      if(ab){
        let data=JSON.parse(ab);
        // console.log("amit1",data);
        setparseData(data)
        setnochanorlat(data?.lat);
        setorginlat(data?.lat);
        setnochangeOrlng(data?.lng);
        setorginlon(data?.lng)
       
        setSold(data?.isSould)
        setairportName(data?.airportName)
        setcheck(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  var center
const handCity=(e)=>{
  try {

  center = new window.google.maps.LatLng(e.lat,-e.lng); 
    if(city){
      if(check==true){
         window.location.reload()
      }
      setcity(e.city)
      sessionStorage.setItem("city",e.city)
    }else{
      sessionStorage.setItem("city",e.city)
      setcity(e.city)
    }
  } catch (error) {
    console.log(error);
  }
}
 

  const [area, setArea] = useState(null);

  function CurrentLocation(){
     let city=sessionStorage.getItem("city")
      if(!city) return erorShow("Please select city")
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
     
  setdistlat(position.coords.latitude);
      setnolat(position.coords.latitude);
      // distanceInKm(13.1989,77.7068,response.data.geometry.location.lat(),response.data.geometry.location.lng())
      setdistlon(position.coords.longitude);
      setnolng(position.coords.longitude);
        // Use a reverse geocoding service to get area information
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          if(response.data?.address?.city!==city) return erorShow(`Your Current  location is not  in side ${city}`)
          setArea(response.data.display_name);
       
      setaddress(response.data.display_name);
      setsamchange(true);
        } catch (error) {
        return erorShow('Error fetching area information:');
        }
      });
    } else {
     return erorShow('Geolocation is not supported by your browser');
    }
  }
  // console.log("Arrea google",area,location)
  return (
    <>
      <div className="tab-he feature">
        <div className="row me-0">
       
          <div  className="col-md-3">  <div className="feature-list mb-3">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setairport(e.target.value)}
          >
         
            <option value={true}>Airport Pickup</option>
            <option value={false}>Airport Dropoff</option>
          </Form.Select>
        </div></div>
        <div  className="col-md-3">  <div className="feature-list mb-3">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) =>{ 
              handCity(JSON.parse(e.target.value))
            }}
          >
            <option >Select City</option>
            {allCity?.map((ele)=>{
              return (
                <option value={JSON.stringify(ele)}>{ele?.city}</option>
              )
            })}
          </Form.Select>
        </div></div>
        {airport=="true" ? (   <div  className="col-md-3">
            <div className="feature-list mb-3">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => HandeleAirport(e.target.value)}
          >
            <option >Select Airport</option>
            {allAirpots?.filter((tem)=>tem?.city==city)?.map((ele)=>{
              return (
                <option value={JSON.stringify(ele)}>{ele?.airportName}</option>
              )
            })}
          </Form.Select>
        </div>
        </div>):(<></>)}
     
          <div  className="col-md-3">
          <div className="feature-list mb-3" style={{display:"flex",justifyContent:"space-between"}}>
            {airport=="true" ? (<></>):(
                 <button className="btn btn-light" onClick={CurrentLocation}><FaLocationCrosshairs style={{color:"green"}}/></button>
            )}
          <Autocomplete
            apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
            placeholder={
              airport == "true"
                ? "Enter your drop place"
                : "Enter your pick up place"
            }
            className="Googlebaba"
            defaultValue={area}
            onPlaceSelected={(place) => {
              handlePlaceChanged(place);
            }}
            options={{
            
              
              componentRestrictions: { country: "in" },
              fields: ["address_components", "geometry", "icon", "name"],
              types: ["establishment"],
              location: center,
              radius: 10000
            }}
          />
          {distlon&&orginlat  ? (
            <DistanceMatrixService
              options={{
                origins: [{ lng: Number(orginlon), lat: Number(orginlat) }],
                destinations: [{ lat: Number(distlat), lng: Number(distlon) }],
              
                travelMode: "DRIVING",
              }}
              // options={{
              //   destinations: [{lat:Number(13.0773806), lng:77.53820429999999}],
              //   origins: [{lng:77.7068, lat:13.1989}],
              //   travelMode: "DRIVING",
              // }}
              callback={(response) => {
                console.log("desti dta", distlat,distlon,"orgin",orginlat,orginlon);
                // console.log("Amit sir", parseInt(response.rows[0].elements[0].distance?.text),response);
                setdistance(
                  parseInt(response.rows[0].elements[0].distance?.text)
                );
              }}
            />
          ) : (
            <></>
          )}
        </div>
          </div>
          {airport=="false" ? (   <div  className="col-md-3">
            <div className="feature-list mb-3">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => HandeleAirport(e.target.value)}
          >
            <option >Select Airport</option>
            {allAirpots?.filter((tem)=>tem?.city==city)?.map((ele)=>{
              return (
                <option value={JSON.stringify(ele)}>{ele?.airportName}</option>
              )
            })}
          </Form.Select>
        </div>
        </div>):(<></>)}
        </div>
      
      
        <div className="feature-list mb-4">
          <div className="hourly">
            <div className="row me-0">
              <div className="col-md-6">
              <div className="hourly_hero">
              <label className="eiggyI pb-2">Pickup Date</label>
              <div className="">
                <DatePicker
                  style={{ height: "38px" }}
                  format="DD/MM/YYYY"
                  type="input-icon"
                  minDate={new Date()}
                  value={date}
                 
                  onChange={(date) => setdate(date.format("DD/MM/YYYY"))}
                />
              </div>
            </div>
              </div>
              <div className="col-md-6">
              <div className="hourly_hero">
              <label className="eiggyI pb-2">Pickup Time</label>
              <div>
                <select
                  name="txtStartTime"
                  className="select_hero-0"
                  onChange={(e) => setpickuptime(e.target.value)}
                >
                    {pickuptime ? (<option>{moment(pickuptime, ["h:mm A"]).format("hh:mm a")}</option>):(<option>Select Time</option>)}
                  {am?.map((item, i) => {
                    return (
                      <option value={item?.time} >
                        {moment(item?.time, ["h:mm A"]).format("hh:mm a")}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
              </div>
            </div>
            
           
          </div>
        </div>
        <div className=" text-center mb-3">
          {/* <Link to="/search"> */}
          <Button
            className="hvr-shutter-out-horizontal"
            onClick={() => checkdata()}
          >
            SEARCH CABS
          </Button>
          {/* </Link> */}
        </div>
      </div>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", }}
        >
          <div className="">
            <div
              className="wrong"
              style={{
                fontSize: " 100px",
                color: "#ff000087",
                textAlign: "center",
              }}
            >
              <RxCrossCircled />
            </div>
            <div className="nf_0">{errorData}</div>
            <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ padding: " 7px 27px", backgroundColor: " #fbcc30" }}
                onClick={() => setModal2Open(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Airports;
