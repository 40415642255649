import React from "react";
import { GoDeviceMobile } from "react-icons/go";

function About() {
  return (
    <>
      <section id="about" className="banner-section ">
        <div className="customer ">
          <div className="container">
            <div className="customer-about">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="plenty">
                    <div className="about-img">
                      <img
                        className="about-img1 wow fade-in-left"
                        data-wow-delay="200ms"
                        src="../Image/caba.jpg"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <div
                      className="section-heading mb-40 wow fade-in-right"
                      data-wow-delay="200ms"
                    >
                      <h4 style={{ color: "#fbcc30" }}>
                        <span />
                        About Our Company
                      </h4>
                      <p className="fwh">India's Most Trusted Cab Services.</p>
                      <p>
                        Book Affordable & hassle free taxi service for Airport,
                        Outstation & Local hire. Call Now
                      </p>
                      <div className="rfd">
                        <GoDeviceMobile />
                        +919538880063
                      </div>
                      <div className="rfd">
                        <GoDeviceMobile />
                        +918660669093
                      </div>
                      <div className="book_0">
                        Book Taxi for Airport, Outstation or local Local Hire
                        from Go Easy Do Cabs at economical fares
                      </div>
                      <p style={{ fontSize: "15px" }}>
                        Enjoy Go Easy Do Cabs car rental services in Bangalore by making
                        a quick and convenient online booking for Outstation Cab
                        Rentals Service, Local Sightseeing Cab Rentals Service,
                        Airport Pickup Drop Taxi Rentals Service. With Go Easy Do Cabs
                        you can get the best prices for outstation, airport or
                        local cabs in Bangalore with our completely transparent
                        pricing. Choose your Taxi from a wide range of vehicle
                        types like Innova, Dezire, Etios, Indigo, Indica, Xylo,
                        Swift, Logan, Verito, and Xcent, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="dqe">
          <div className="container">
            <div className="irgr">
              <div className="row">
                <div className="col-md-4">
                  <div className="vov">
                    <h4  style={{fontWeight:"bold",color:"black",textAlign:"center"}}>OutStation</h4>
                    <div className="outst">
                      <img src="../Image/outstation.webp" alt="ef" style={{width:"100%",height:"210px"}}/>
                    </div>
                    <div className="ewfwrv" style={{ textAlign: "justify" }}>
                    &nbsp;&nbsp;  When we plan to book outstation cabs online we consider a
                      lot of thing, which outstation taxi service offers the
                      best in class experience at lowest fares, on-time pickup,
                      no last minute cancellation, professional drivers'
                      behaviours.
                    </div>
                    <div className="ewfwrv" style={{ textAlign: "justify" }}>
                    &nbsp;&nbsp;  Quick Go Easy Do Cab fares are transparent, and 5-10% lower for
                      outstation travels. No Hidden Charges. No Extra Fares. No
                      Additional Charges for Pre-booking. Book your Lowest Fare
                      Outstation Taxi now. 
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="vov">
                    <h4  style={{fontWeight:"bold",color:"black",textAlign:"center"}}>Local Cabs in Bangalore</h4>
                    <div className="outst">
                      <img src="../Image/localcab.webp" alt="ef" style={{width:"100%",height:"210px"}}/>
                    </div>
                    <div className="ewfwrv" style={{ textAlign: "justify" }}>
                    &nbsp;&nbsp;  Bangalore is a city that need to be explored for its
                      variety of food, heritage, cultures, monuments, parks,
                      lakes pubs and what not. We make sure that you invest more
                      time in exploration and less in traffic. 
                    </div>
                    <div  className="ewfwrv" style={{ textAlign: "justify" }}>&nbsp;&nbsp;  We offer fixed
                      price booking for local call taxi in Bangalore at the
                      cheapest price. You can also choose from our cab rental
                      packages starting from 1 Hour / 10Km till 12 Hour / 120
                      Kms. Hire the cheapest local cab services in Bangalore..</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="vov">
                    <h4 style={{fontWeight:"bold",color:"black",textAlign:"center"}}>Airport</h4>
                    <div className="outst">
                      <img src="../Image/airport.jpg" alt="ef" style={{width:"100%",height:"210px"}}/>
                    </div>
                    <div className="ewfwrv" style={{ textAlign: "justify" }}>
                    &nbsp;&nbsp;  Book Airport Taxi All our drivers ensure all the
                      passengers are safe when we drop them off. Our drivers
                      don’t leave from the drop off point or destination until
                      passengers enter their respective house or chosen
                      destination. 
                    </div>
                    <div className="ewfwrv " style={{ textAlign: "justify" }}>&nbsp;&nbsp;  Customers being our priority, we are always
                      happy to help with your luggage too. If you are about to
                      arrive at, you can book our cab service and we guarantee
                      that our driver will be there for you. </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
