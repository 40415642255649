import * as React from "react";
import Box from "@mui/material/Box";
import { Col, Container, Row } from "react-bootstrap";
import Adminheader from "./Adminheader";
import Sidebar1 from "./Sidebar1";

function AdminPanel({ children }) {
//   const admin = JSON.parse(sessionStorage.getItem("admin"));

  return (
    <>
      {/* {admin ? ( */}
        <>
          <Adminheader />
          <Box sx={{ display: "flex" }}>
            <Sidebar1 />
            <Container style={{width:"80%"}}>
              <Row>
                <Col>
                  <div className="mt-5">{children}</div>
                </Col>
              </Row>
              {/* <div
                style={{
                  color: "black",
                  bottom: "10px",
                  position: "fixed",
                  right: 10,
                  marginTop:20
                }}
              >
                Designed & Developed By Parnets Group © 2023
              </div> */}
            </Container>
          </Box>
        </>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default AdminPanel;
